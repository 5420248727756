import {List} from '@dropbox/dig-components/dist/list';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {StarFill} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {BadgeLeaderboard as BadgeLeaderboardModel, BadgeService} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {t} from 'i18next';
import {useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import styles from './AllBadgesList.module.css';

const BadgeSkeletonItem = () => (
  <List.Item padding={'10px var(--spacing__unit--4)'}>
    <List.Accessory style={{width: 24, textAlign: 'right'}}>
      <Skeleton.Text width={24} />
    </List.Accessory>
    <List.Content>
      <Split alignY="center" gap="8">
        <Split.Item>
          <Skeleton.Avatar size="large" />
        </Split.Item>
        <Split.Item width="fill">
          <Skeleton.Text size="large" width={160} />
        </Split.Item>
        <Split.Item style={{width: 70}}>
          <Skeleton.Text size="large" width={70} />
        </Split.Item>
      </Split>
    </List.Content>
  </List.Item>
);

const LeaderboardItem = ({
  item: {employee, badge_count},
  rank,
  closeModal,
}: {
  item: BadgeLeaderboardModel;
  rank: number;
  closeModal: () => void;
}) => {
  const navigate = useNavigate();

  const goToEmployee = (ldap: string) => {
    closeModal();
    navigate(`/people/${ldap}`);
  };
  return (
    <List.Item padding={'10px var(--spacing__unit--4)'} onClick={() => goToEmployee(employee.ldap)}>
      <List.Accessory style={{width: 24, textAlign: 'right'}}>
        <Text size="large" color="faint">
          {rank + 1}.
        </Text>
      </List.Accessory>
      <List.Content>
        <Split alignY="center" gap="8">
          <Split.Item>
            <Avatar user={employee} />
          </Split.Item>
          <Split.Item width="fill">
            <Split alignY="center">
              <Split.Item>
                <Text size="large">{employee.name}</Text>
              </Split.Item>
              <Split.Item>
                {rank < 3 && <UIIcon src={StarFill} color={['gold', 'silver', '#B87333'][rank]} />}
              </Split.Item>
            </Split>
          </Split.Item>
          <Split.Item>
            <Box>
              <Text size="large" color="faint">
                {t('badge', {count: badge_count})}
              </Text>
            </Box>
          </Split.Item>
        </Split>
      </List.Content>
    </List.Item>
  );
};

export const BadgeLeaderboard = ({closeModal}: {closeModal: () => void}) => {
  const {data: leaderboard} = useQuery({
    queryKey: ['badgeLeaderboard'],
    queryFn: getService(BadgeService).leaderboardApiV1BadgesLeaderboardGet,
  });

  let currentRank = 0;
  let previousBadgeCount = leaderboard?.[0]?.badge_count ?? 0;
  let displayedRank = 0;

  return (
    <div className={styles.container}>
      <Box marginX="32" paddingTop="16">
        <Text variant="label" size="small" color="faint" className={styles.supportingText}>
          {t('leaderboard_supporting_text')}
        </Text>
      </Box>
      <List isSelectable paddingX="0" spacing="large">
        {!leaderboard
          ? Array.from({length: 50}).map((_, key) => <BadgeSkeletonItem key={key} />)
          : leaderboard.map((item) => {
              if (item.badge_count < previousBadgeCount) {
                currentRank++;
                displayedRank = currentRank;
              }

              previousBadgeCount = item.badge_count;

              return (
                <LeaderboardItem
                  key={item.employee.user_id}
                  item={item}
                  rank={displayedRank}
                  closeModal={closeModal}
                />
              );
            })}
      </List>
    </div>
  );
};
