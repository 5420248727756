import {useCallback, useEffect} from 'react';

export const useChartExpand = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  expanded: boolean,
  recenterChart: (obj: {resize: boolean}) => void
) => {
  useEffect(() => {
    if (!wrapperRef.current) return;

    const element = wrapperRef.current;
    const rect = element.getBoundingClientRect();

    // Get the bounding box of the element
    if (expanded) {
      // Calculate target dimensions and position for full viewport
      const initialWidth = rect.width;
      const initialHeight = rect.height;
      const initialTop = rect.top;
      const initialLeft = rect.left;

      // Animate to full viewport size
      element.style.position = 'fixed';
      element.style.top = `${initialTop}px`;
      element.style.left = `${initialLeft}px`;
      element.style.width = `${initialWidth}px`;
      element.style.height = `${initialHeight}px`;

      // Trigger reflow for the browser to recognize the initial state
      void element.offsetHeight;

      // Animate to full viewport size
      element.style.transition = 'width 0.4s ease, height 0.4s ease, top 0.4s ease, left 0.4s ease';
      element.style.top = '78px';
      element.style.left = '82px';
      element.style.width = 'calc(100vw - 140px)';
      element.style.height = 'calc(100vh - 178px)';
      element.style.zIndex = '11';

      document.body.style.overflow = 'hidden';
    } else {
      // Reset to initial state
      element.style.width = '';
      element.style.height = '';
      element.style.top = '';
      element.style.left = '';
      element.style.position = 'relative';
      element.style.transition = 'width 0s ease, height 0s ease, top 0s ease, left 0s ease';
      element.style.zIndex = '';
      document.body.style.overflow = '';
    }
  }, [expanded, wrapperRef]);

  useEffect(() => {
    const animateCentering = () => {
      recenterChart({resize: true});

      // Continue the animation loop until expansion is complete
      animationFrame = requestAnimationFrame(animateCentering);
    };

    let animationFrame = requestAnimationFrame(animateCentering);

    setTimeout(() => {
      cancelAnimationFrame(animationFrame);
    }, 900);

    return () => cancelAnimationFrame(animationFrame);
  }, [expanded, recenterChart]);
};

export const useChartResize = (recenterChart: (obj: {resize: boolean}) => void) => {
  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        recenterChart({resize: true});
      }, 300);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [recenterChart]);
};

export const useChartKeyboardShortcuts = ({
  expanded,
  adjustZoom,
  resetZoom,
}: {
  expanded: boolean;
  adjustZoom: (zoom: number) => void;
  resetZoom: () => void;
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!expanded) {
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '0') {
        event.preventDefault();
        resetZoom();
      }

      if ((event.metaKey || event.ctrlKey) && (event.key === '+' || event.key === '=')) {
        event.preventDefault();
        adjustZoom(1.2);
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '-') {
        event.preventDefault();
        adjustZoom(0.8);
      }
    },
    [adjustZoom, expanded, resetZoom]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
