import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, MoreHorizontalLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {BadgeMini} from '@dropbox/dig-illustrations';
import {Employee} from 'client';
import {t} from 'i18next';
import {useState} from 'react';

import {Avatar} from './Avatar';
import styles from './EmployeeListTypeahead.module.css';
import {EmployeeTypeahead} from './EmployeeTypeahead';
import {EmptyState} from './EmptyState';

export const SavableEmployeeListTypeahead = ({
  isPending,
  hasChanges,
  initialSelections,
  memberSort,
  withOverflowMenuItems,
  withRightAccessory,
  onSave,
  onCancel,
  onChange,
}: {
  isPending?: boolean;
  hasChanges?: boolean;
  initialSelections: Employee[];
  memberSort?: (a: Employee, b: Employee) => number;
  withOverflowMenuItems?: (employee: Employee) => React.ReactNode;
  withRightAccessory?: (employee: Employee) => React.ReactNode;
  onSave: (selections: Employee[]) => void;
  onCancel: () => void;
  onChange?: (selections: Employee[]) => void;
}) => {
  const [selections, setSelections] = useState(initialSelections);

  return (
    <Stack>
      <EmployeeListTypeahead
        editable
        memberSort={memberSort}
        selections={selections}
        setSelections={(updated) => {
          setSelections(updated);
          onChange?.(updated);
        }}
        withOverflowMenuItems={withOverflowMenuItems}
        withRightAccessory={withRightAccessory}
      />
      <Box display="flex" justifyContent="flex-end" className={atoms({paddingTop: '60'})}>
        <Button
          variant="opacity"
          onClick={onCancel}
          className={atoms({marginRight: '8'})}
          disabled={isPending}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => onSave(selections)}
          isLoading={isPending}
          disabled={!hasChanges}
        >
          {t('save')}
        </Button>
      </Box>
    </Stack>
  );
};

export const EmployeeListTypeahead = ({
  editable,
  memberSort,
  withOverflowMenuItems,
  withRightAccessory,
  selections,
  setSelections,
}: {
  editable?: boolean;
  memberSort?: (a: Employee, b: Employee) => number;
  withOverflowMenuItems?: (employee: Employee) => React.ReactNode;
  withRightAccessory?: (employee: Employee) => React.ReactNode;
  selections: Employee[];
  setSelections: (employee: Employee[]) => void;
}) => {
  const removeEmployee = (selection: Employee) => {
    setSelections(selections.filter((e) => e.user_id !== selection.user_id));
  };

  if (!selections.length) {
    return (
      <Stack>
        {editable && (
          <EmployeeTypeahead
            selections={selections.map(({email}) => email ?? '')}
            onMultiSelect={(employees) => setSelections([...selections, ...employees])}
            placeholder={[]}
          />
        )}
        <EmptyState
          title={t('no_members_title')}
          body={t('no_members_subtitle')}
          image={<BadgeMini width={64} altText={''} />}
        />
      </Stack>
    );
  }

  return (
    <Stack>
      {editable && (
        <EmployeeTypeahead
          selections={selections.map(({email}) => email ?? '')}
          onMultiSelect={(employees) => setSelections([...selections, ...employees])}
          placeholder={[]}
        />
      )}

      <Text size="large" isBold className={atoms({paddingBottom: '8', display: 'block'})}>
        {t('members_count', {count: selections.length})}
      </Text>

      <Box style={{marginLeft: -8, marginRight: -8}}>
        {selections.sort(memberSort).map((member) => (
          <Box
            key={member.user_id}
            paddingY="8"
            paddingX="12"
            borderRadius="Medium"
            width="100%"
            overflow="hidden"
            {...withShade({
              duration: 'None',
              bgColors: {active: 'Opacity Surface'},
              className: styles.row,
            })}
          >
            <Box
              display="inline"
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar user={member} className={atoms({marginRight: '8'})} />
                <Box className={atoms({paddingRight: '116', overflow: 'hidden'})}>
                  <Text isBold className={atoms({display: 'block'})}>
                    {member.name}
                  </Text>
                  <Text
                    color="subtle"
                    size="small"
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {member.role}
                  </Text>
                </Box>
              </Box>

              {editable && withOverflowMenuItems && (
                <Box
                  position="absolute"
                  className={styles.button}
                  style={{
                    top: 12,
                    right: selections.length <= 1 ? 8 : 44,
                  }}
                >
                  <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
                    {({getContentProps, getTriggerProps}) => (
                      <>
                        <IconButton
                          {...getTriggerProps()}
                          variant="transparent"
                          className={atoms({marginRight: '4'})}
                        >
                          <UIIcon
                            src={MoreHorizontalLine}
                            className={atoms({color: 'Border Base'})}
                          />
                        </IconButton>
                        <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom">
                          <Menu.Segment>{withOverflowMenuItems(member)}</Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                </Box>
              )}
              {editable && (
                <Box
                  position="absolute"
                  className={styles.button}
                  style={{
                    top: 12,
                    right: 8,
                    display: selections.length <= 1 ? 'none' : 'block',
                  }}
                >
                  <IconButton variant="transparent" onClick={() => removeEmployee(member)}>
                    <UIIcon src={DeleteLine} className={atoms({color: 'Border Base'})} />
                  </IconButton>
                </Box>
              )}

              {withRightAccessory && (
                <Box
                  position="absolute"
                  className={styles.chip}
                  style={{
                    top: 12,
                    right: 8,
                    // display: editable ? 'none' : 'block',
                  }}
                >
                  {withRightAccessory(member)}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
