import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {Team, TeamInfo, TeamWithCounts} from 'client';
import {TeamMembersAndSubTeamsCounts} from 'components/shared/TeamMembersAndSubTeamsCounts';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useEffect, useRef, useState} from 'react';

export const TeamSearch = ({
  team,
  isInvalid,
  selectedTeam,
  handleTeamSelected,
}: {
  team?: TeamInfo;
  isInvalid?: boolean;
  selectedTeam?: TeamInfo;
  handleTeamSelected: (team: TeamInfo) => void;
}) => {
  const isDropdownClick = useRef<boolean>(false);

  const [inputValue, setInputValue] = useState('');
  const searchedTeamResults = useGlobalSearch({
    input: inputValue,
    filter: 'teams',
    limit: 50,
    showMore: true,
  });

  useEffect(() => {
    if (!selectedTeam?.name) {
      return;
    }
    setInputValue(selectedTeam.name);
  }, [selectedTeam]);

  const searchedTeams = searchedTeamResults
    .map((result) => result as TeamWithCounts)
    .filter(({slug}) => team?.slug !== slug);

  const inputContainerRef = useRef<HTMLElement | null>(null);

  const handleSelection = (selection: Team) => {
    handleTeamSelected(selection as unknown as TeamInfo);
  };

  const renderTypeaheadRow = (result: TeamWithCounts) => {
    return (
      <Typeahead.Row
        key={result.slug}
        onMouseDown={() => (isDropdownClick.current = true)}
        value={result}
        withTitle={
          <Text isBold>
            <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
              {result.name}
            </Box>
          </Text>
        }
        withSubtitle={
          <TeamMembersAndSubTeamsCounts
            membersCount={result.members_count ?? 0}
            subTeamsCount={result.subteams_count ?? 0}
          />
        }
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
  };

  const handleInputBlur = () => {
    if (isDropdownClick.current) {
      isDropdownClick.current = false;
      return;
    }
    setInputValue(selectedTeam?.name ?? ''); // Reset to selected value
  };

  return (
    <Typeahead.Wrapper isPortaled={false} onSelection={handleSelection} onBlur={handleInputBlur}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput.Container ref={inputContainerRef} isInvalid={isInvalid}>
            <TextInput.Accessory>
              <UIIcon src={SearchLine} />
            </TextInput.Accessory>
            <TextInput.Input
              placeholder={t('search_teams')}
              value={inputValue}
              {...getTriggerProps({
                onChange: onInputChange,
                onKeyDown: handleKeyDown,
              })}
            />
          </TextInput.Container>
          <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
            {searchedTeams.length ? (
              <Typeahead.Results
                results={searchedTeams}
                renderRow={renderTypeaheadRow}
                initialResults={4}
                maxResults={searchedTeams.length}
              />
            ) : inputValue.length ? (
              <Typeahead.Results results={[{}]} renderRow={renderNoResultsRow} />
            ) : null}
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};
