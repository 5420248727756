import {Text} from '@dropbox/dig-components/dist/typography';
import {Skeleton} from '@dropbox/dig-components/skeleton';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {useColumnResize} from 'components/shared/table/useColumnResize';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {useAtomValue} from 'jotai';
import {Suspense, useCallback} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {EmptyErrorGoalsTable} from 'views/goals_v2/EmptyGoalsTable';
import {EmployeeGoalsTableComponent} from 'views/goals_v2/TableComponents/EmployeeGoalsTableComponent';
import {DrawerGoalData} from 'views/goals_v2/types';
import {getBrowsePath} from 'views/goals_v2/utils';

import styles from './GoalsList.module.css';
import {useGoals} from './hooks';

interface GoalsListProps {
  type: 'employee' | 'self';
  timeframe: string;
  ldaps?: string[];
  onClick?: (value: string) => void;
}

export const GoalsList = (props: GoalsListProps) => {
  return (
    <ErrorBoundary
      key={`profile_goals_error-${props.ldaps?.join('-')}`}
      FallbackComponent={() => <EmptyErrorGoalsTable />}
    >
      <Suspense fallback={<GoalsListSkeleton />}>
        <GoalListForType {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

const GoalListForType = (props: GoalsListProps) => {
  switch (props.type) {
    case 'self':
      return <MyGoals {...props} />;
    case 'employee':
      return <EmployeeGoals {...props} />;
  }
};

const MyGoals = ({timeframe, onClick}: GoalsListProps) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  return <GoalsV2 ldap={employee.ldap} timeframe={timeframe} onClick={onClick} />;
};

const EmployeeGoals = ({timeframe, ldaps = [], onClick}: GoalsListProps) => {
  const {numOfPrivateGoals, hasAllPrivateGoals} = useGoals({ldaps, timeframe});

  return (
    <GoalsV2
      ldap={ldaps[0]}
      timeframe={timeframe}
      numOfPrivateGoals={numOfPrivateGoals}
      onClick={onClick}
      showNumOfPrivateGoals={!hasAllPrivateGoals}
    />
  );
};

const GoalsV2 = ({
  ldap,
  timeframe,
  numOfPrivateGoals = 0,
  onClick,
  showNumOfPrivateGoals,
}: {
  ldap: string | undefined;
  timeframe: string;
  numOfPrivateGoals?: number;
  onClick?: (value: string) => void;
  showNumOfPrivateGoals?: boolean;
}) => {
  const {t} = useTranslation();
  const {columnConfigs, getMouseDownHandler} = useColumnResize([
    {type: 'name', width: 540, minWidth: 540, maxWidth: 540, fixed: true},
    {type: 'status', width: 70, fixed: true},
    {type: 'contributors', width: 70, fixed: true},
  ]);
  const navigate = useNavigate();

  const handleNavigateToGoal = useCallback(
    (goalData: DrawerGoalData) => {
      onClick?.(goalData?.keyResultId ? 'profile-kr' : 'profile-goal');
      if (goalData.goalId) {
        navigate(getBrowsePath(goalData, false), {
          state: {source: 'profile'},
        });
      }
    },
    [navigate, onClick]
  );

  if (!ldap) {
    return <GoalsListSkeleton />;
  }

  const keyPrefix = 'profile_goals';
  return (
    <>
      <EmployeeGoalsTableComponent
        index={0}
        key={`${keyPrefix}-${ldap}`}
        keyPrefix={keyPrefix}
        ldap={ldap}
        timeframe={timeframe}
        nextTimeframe={getNextQuarter(timeframe)}
        setNextTimeframe={() => {}}
        columnConfigs={columnConfigs}
        onColumnDrag={getMouseDownHandler}
        isGoalsV2DrawerOpen={false}
        onRowClick={handleNavigateToGoal}
        onContributorsClick={handleNavigateToGoal}
        onContinueGoalClick={() => {}}
        hasScrolledToRow={true}
        setScrolledToRow={() => {}}
        selectedGoalData={undefined}
        showActionMenu={false}
        showHeader={false}
        showUpdates={false}
        disableShowTitle={true}
        disableExpandGoalActionMenu={true}
        disableTooManyGoalsEmptyState={true}
        shouldExcludeUnownedGoals={true}
        containerProps={{
          marginTop: '8',
          paddingX: undefined,
          paddingY: undefined,
          borderRadius: undefined,
          borderColor: undefined,
          borderStyle: undefined,
          borderWidth: undefined,
        }}
        rowProps={{
          borderRadius: 'Medium',
          paddingRight: '8',
        }}
        emptyStateContainerProps={{marginTop: '8'}}
      />
      {showNumOfPrivateGoals && numOfPrivateGoals > 0 && (
        <Text
          color="faint"
          size="small"
          style={{display: 'flex', alignItems: 'center', marginTop: 8}}
        >
          <div className={styles.privateGoalsVector}></div>
          {t('num_private_goals', {count: numOfPrivateGoals})}
        </Text>
      )}
    </>
  );
};

const GoalsListSkeleton = () => (
  <div
    style={{
      display: 'flex',
      gap: '40px',
      flexDirection: 'column',
    }}
  >
    <Skeleton.Box height={52} withBorderRadius={'medium'} />
    <Skeleton.Box height={52} withBorderRadius={'medium'} />
    <Skeleton.Box height={52} withBorderRadius={'medium'} />
  </div>
);
