import {RadioButton} from '@dropbox/dig-components/dist/controls';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {EmployeeService} from 'client';
import {useAtomValue} from 'jotai';
import {useEffect, useMemo, useState} from 'react';
import {getService} from 'utilities';

import {OrgChart} from './Chart';
import {getUid, HierarchyData} from './helpers';

export function getHierarchy(root: HierarchyData, targetLdap: string): HierarchyData | null {
  // Helper function to find the path to the target employee
  function findPath(
    node: HierarchyData,
    target: string,
    path: HierarchyData[] = []
  ): HierarchyData[] | null {
    if (getUid(node) === target) return [...path, node];

    if (node.children) {
      for (const child of node.children) {
        const result = findPath(child, target, [...path, node]);
        if (result) return result;
      }
    }

    return null;
  }

  const pathToEmployee = findPath(root, targetLdap);
  if (!pathToEmployee) return null;

  // Build the selected employee's node, preserving only direct children
  let currentLevel: HierarchyData = {
    ...pathToEmployee[pathToEmployee.length - 1],
    children: pathToEmployee[pathToEmployee.length - 1].children?.map((child) => ({
      ...child,
      // children: undefined,
      // Clear childrens grandchildren
      children:
        getUid(child) === targetLdap
          ? []
          : (child.children?.map((grandchild) => ({
              ...grandchild,
              children: undefined,
            })) as any),
    })) as any,
  };

  // Traverse upwards from the selected employee's level to root
  for (let i = pathToEmployee.length - 2; i >= 0; i--) {
    const currentNode = {...pathToEmployee[i]} as any;

    // If we're at the manager's level, include all peers of the selected employee
    if (i === pathToEmployee.length - 2) {
      currentNode.children = (currentNode.children?.map((child: any) => ({
        ...child,
        children: getUid(child) === getUid(currentLevel) ? currentLevel.children : undefined,
      })) || []) as any;

      currentLevel = currentNode;
    } else {
      // For higher levels, only keep the relevant path to the target employee
      currentNode.children = [currentLevel];
      currentLevel = currentNode;
    }
  }

  return currentLevel;
}

export const D3OrgChart = () => {
  // Pass the selected node's name as a prop to OrgChart

  const {employee, employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  // const {data: team} = useQuery({
  //   queryKey: ['teams', 'all'],
  //   queryFn: () => getService(TeamService).getTeamsAllApiV1TeamsAllGet(),
  // });

  const {data: people} = useQuery({
    queryKey: ['people', 'tree', employee.ldap!],
    queryFn: () => getService(EmployeeService).getTreeApiV1PeopleLdapTreeGet(employee.ldap!),
    enabled: !!employee?.ldap,
  });

  const [selection, setSelection] = useState<string | undefined>('drew');
  const [focus, setFocus] = useState<string>('drew');
  const [type, setType] = useState<'employee' | 'team'>('employee');

  useEffect(() => {
    // if (type === 'team') {
    //   if (employeeTeams?.length) {
    //     setSelection(employeeTeams?.[0].slug || 'dropbox-teams');
    //   }
    // } else {
    setSelection(employee?.ldap || 'drew');
    setFocus(employee?.ldap || 'drew');
    // }
  }, [employee?.ldap, employeeTeams?.length, type]);

  const chartData = useMemo(() => {
    if (!people || !selection) return undefined;

    return people;
  }, [people, selection]);

  return (
    <Box padding="16">
      <FormRow>
        <Split alignY="center">
          <Split.Item width="fill">
            <RadioButton
              aria-label="radio employee"
              id="radio-employee"
              checked={type === 'employee'}
              onChange={() => setType('employee')}
            />
            <Text tagName="label" htmlFor="radio-employee">
              Employees
            </Text>

            <RadioButton
              aria-label="radio team"
              id="radio-team"
              checked={type === 'team'}
              onChange={() => setType('team')}
            />
            <Text tagName="label" htmlFor="radio-team">
              Teams
            </Text>
          </Split.Item>
        </Split>
      </FormRow>
      {chartData && (
        <OrgChart
          data={chartData}
          toggle={{
            ldap: employee.ldap,
            slug: 'dropbox-teams',
          }}
          focus={focus}
          setFocus={setFocus}
          expanded={false}
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </Box>
  );
};
