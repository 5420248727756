import {Checkbox} from '@dropbox/dig-components/controls';
import {TextInput, TextInputRefObject} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import styles from 'components/goals/edit/StrategyConnectSearch.module.css';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect, useRef, useState} from 'react';

export const GoalEmployeeSearch = ({
  employees = [],
  handleEmployeeSelected,
}: {
  employees?: string[];
  handleEmployeeSelected: (employees: string[]) => void;
}) => {
  const [inputValue, setInputValue] = useState('');
  const searchedTeamResults = useGlobalSearch({
    input: inputValue,
    filter: 'people',
    showMore: false,
  });
  // const selectedEmployees = useEmployees({ldaps: employees});

  const searchedEmployees = [
    // ...(inputValue.length ? [] : selectedEmployees),
    ...searchedTeamResults.map((result) => result as Employee),
    // .filter(
    //   (employee) =>
    //     !selectedEmployees.some((selectedEmployee) => selectedEmployee.ldap === employee.ldap)
    // ),
  ];

  const inputContainerRef = useRef<HTMLElement | null>(null);

  const handleSelection = (employee: Employee) => {
    const isChecked = employees.findIndex((ldap) => ldap === employee.ldap) !== -1;
    const newSelected = isChecked
      ? [...employees.filter((ldap) => ldap !== employee.ldap)]
      : [employee.ldap, ...employees];
    handleEmployeeSelected(newSelected);
  };

  const renderTypeaheadRow = (employee: Employee) => {
    const isChecked = employees.findIndex((ldap) => ldap === employee.ldap) !== -1;
    return (
      <Typeahead.Row
        key={employee.ldap}
        value={employee}
        withTitle={
          <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
            {employee.name}
          </Box>
        }
        role="menuitemcheckbox"
        aria-label={`Option ${employee.ldap}`}
        aria-checked={isChecked}
        withLeftAccessory={<Checkbox checked={isChecked} readOnly tabIndex={-1} />}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.objectiveActionContainer}>
      <Typeahead.Wrapper
        isPortaled={false}
        onSelection={handleSelection}
        closeOnSelection={false}
        className={styles.strategySelectOptions}
      >
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInput.Container ref={inputContainerRef} style={{maxWidth: '241px'}}>
              <TextInput.Accessory>
                <UIIcon src={SearchLine} />
              </TextInput.Accessory>
              <TextInput.Input
                placeholder={
                  employees.length
                    ? t('people_selected', {count: employees.length})
                    : t('search_dropboxers').toString()
                }
                value={inputValue}
                {...getTriggerProps({
                  onChange: onInputChange,
                  onKeyDown: handleKeyDown,
                })}
                style={{fontSize: 14}}
              />
            </TextInput.Container>
            <Typeahead.Container
              {...getContentProps()}
              className={styles.typeaheadResultsContainer}
              triggerRef={inputContainerRef}
            >
              {searchedEmployees.length ? (
                <Box overflowY="auto" style={{maxHeight: 145}}>
                  <Typeahead.Results
                    results={searchedEmployees}
                    renderRow={renderTypeaheadRow}
                    initialResults={8}
                    maxResults={8}
                  />
                </Box>
              ) : inputValue.length ? (
                <Typeahead.Results results={[{}]} renderRow={renderNoResultsRow} />
              ) : null}
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    </div>
  );
};

export const JoinGoalEmployeeSearch = ({
  employees = [],
  handleEmployeeSelected,
  delegateLdap,
}: {
  employees?: string[];
  handleEmployeeSelected: (employees: string[]) => void;
  delegateLdap?: string;
}) => {
  const [inputValue, setInputValue] = useState('');
  const searchedTeamResults = useGlobalSearch({
    input: inputValue,
    filter: 'people',
    showMore: false,
  });
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const searchedEmployees = [
    // ...(inputValue.length ? [] : selectedEmployees),
    ...searchedTeamResults
      .map((result) => result as Employee)
      .filter((e) => (delegateLdap ? e.ldap != delegateLdap : e.ldap !== loggedInEmployee.ldap)),
  ];

  const inputContainerRef = useRef<TextInputRefObject | null>(null);

  const handleSelection = (employee: Employee) => {
    const newSelected = [employee.ldap];
    handleEmployeeSelected(newSelected);
  };

  const renderTypeaheadRow = (employee: Employee) => {
    const isChecked = employees.findIndex((ldap) => ldap === employee.ldap) !== -1;
    return (
      <Typeahead.Row
        key={employee.ldap}
        value={employee}
        withTitle={
          <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
            {employee.name}
          </Box>
        }
        role="menuitemcheckbox"
        aria-label={`Option ${employee.ldap}`}
        aria-checked={isChecked}
        withLeftAccessory={<Avatar user={employee} size="small" />}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    // wait for 100 millsec before focusing on the input so that the input trigger ref is rendered.
    setTimeout(() => {
      if (inputContainerRef.current) {
        inputContainerRef.current.focus();
      }
    }, 100);
  }, []);

  return (
    <Typeahead.Wrapper isPortaled={true} onSelection={handleSelection} closeOnSelection={true}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            {...getTriggerProps({
              onChange: onInputChange,
              onKeyDown: handleKeyDown,
            })}
            value={inputValue}
            placeholder={t('search_dropboxers').toString()}
            autoFocus={false}
            withLeftAccessory={<UIIcon src={SearchLine} />}
            ref={inputContainerRef}
            style={{fontSize: 14}}
          />
          <Typeahead.Container
            {...getContentProps()}
            triggerRef={inputContainerRef}
            placement="bottom-start"
            positioningStrategy="fixed"
            auto={false}
          >
            {searchedEmployees.length ? (
              <Typeahead.Results
                results={searchedEmployees}
                renderRow={renderTypeaheadRow}
                initialResults={8}
                maxResults={8}
              />
            ) : inputValue.length ? (
              <Typeahead.Results results={[{}]} renderRow={renderNoResultsRow} />
            ) : null}
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};
