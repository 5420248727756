import {Button} from '@dropbox/dig-components/dist/buttons';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import cx from 'classnames';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {t} from 'i18next';
import {useCallback} from 'react';

import {useProject} from './hooks';
import styles from './ProjectStatusButton.module.css';

export const statusToColor = (status?: string) => {
  // Planning
  if (status?.startsWith('🛑')) {
    return 'var(--dig-color__highlight__red)';
  }

  if (['💡 Backlog / Not Committed', 'Ideation', '🥚 Not Started'].includes(status ?? '')) {
    return 'var(--dig-color__highlight__purple)';
  }

  // Product
  if (
    status?.startsWith('🔍') ||
    status?.startsWith('✏️') ||
    status?.startsWith('🏠') ||
    status?.startsWith('🎨') ||
    status?.startsWith('📊')
  ) {
    return 'var(--dig-color__highlight__blue)';
  }

  // Engineering
  if (status?.startsWith('⚒️')) {
    return 'var(--dig-color__highlight__yellow)';
  }

  // Complete
  if (['⌛ Running', '✅ Done', "✅ GA'ed", 'Ended'].includes(status ?? '')) {
    return 'var(--dig-color__highlight__green)';
  }

  return 'var(--dig-color__secondary__surface)';
};

export const ProjectStatusButton = ({
  projectId,
  fullWidth,
  size,
}: {
  size?: 'small' | 'large' | 'icon';
  fullWidth?: boolean;
  projectId: number;
  onClick: () => void;
}) => {
  const {data} = useProject({id: projectId});

  return !data ? null : (
    <Box
      as={Text}
      display={size === 'large' ? 'block' : 'inline-block'}
      isBold
      size={size === 'large' ? 'medium' : 'small'}
      paddingX="10"
      paddingY="6"
      borderRadius="Medium"
      style={{
        maxWidth: !fullWidth ? 113 : undefined,
        backgroundColor: statusToColor(data.project?.latest_update?.status),
      }}
    >
      <Truncate tooltipControlProps={{placement: 'top'}}>
        <Box>{t(data.project?.latest_update?.status ?? 'no_status').toString()}</Box>
      </Truncate>
    </Box>
  );
};

export const ProjectStatusButton2 = ({
  size = 'large',
  projectId,
  onClick,
}: {
  size?: 'small' | 'large' | 'icon';
  projectId: number;
  onClick: () => void;
}) => {
  const {data, isLoading} = useProject({id: projectId});

  const navigateToProjectUpdates = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return !data ? null : (
    <>
      <Box
        as={Button}
        isLoading={isLoading}
        variant="opacity"
        withIconStart={<StatusButtonIcon status={data.project?.latest_update?.status} />}
        className={cx(styles.statusButton, {
          [styles.iconButton]: size === 'icon',
          [styles.statusButtonSmall]: size === 'small',
        })}
        onClick={navigateToProjectUpdates}
        size={size === 'icon' ? 'large' : size}
      >
        {size !== 'icon' && (
          <Text isBold size={size}>
            {t(data.project?.latest_update?.status ?? 'no_status').toString()}
          </Text>
        )}
      </Box>
    </>
  );
};

export const ProjectStatusButtonReadOnly = ({
  size = 'small',
  showIcon,
  status,
  removePadding,
  colon,
}: {
  size?: 'small' | 'large';
  showIcon?: boolean;
  status?: string;
  // substatus?: string;
  removePadding?: boolean;
  colon?: boolean;
}) => (
  <Box
    as={Button}
    variant="transparent"
    hasNoUnderline
    disabled
    withIconStart={showIcon && <StatusButtonIcon status={status} />}
    className={cx(styles.readButton, {
      [styles.statusButtonSmall]: size === 'small',
      [styles.removePadding]: removePadding,
    })}
    size={size}
    style={
      colon
        ? {
            height: 12,
          }
        : undefined
    }
  >
    <Text isBold size="small">
      {t(status ?? 'no_status').toString()}
      {colon ? ':' : ''}
    </Text>
  </Box>
);
