import {Employee, EmployeeFull, ProfilePage, Team, UserService} from 'client';
import {Atom, atom} from 'jotai';
import {atomWithImmer} from 'jotai-immer';
import {getService} from 'utilities';

type LoggedInEmployeeAtomType = Omit<ProfilePage, 'employeeTeams'> & {
  employeeTeams: Array<Team> | null;
};

export const loggedInEmployeeAtom = atomWithImmer<LoggedInEmployeeAtomType>({
  employee: {} as EmployeeFull,
  reportingLine: [],
  directReports: [],
  teamMembers: [],
  delegates: [],
  delegatedBy: [],
  employeeTeams: null,
  workingWithMe: '',
  admin: {} as Employee,
});
export type LoggedInEmployeeAtomValue =
  typeof loggedInEmployeeAtom extends Atom<infer T> ? T : never;

// Derived atom from userProfileAtom to more easily manage modifying delegates
export const delegatesAtom = atom(
  (get) => get(loggedInEmployeeAtom).delegates,
  async (_, set, updatedDelegates: Employee[]) => {
    const ldapArray = updatedDelegates.map(({ldap}) => ldap);
    await getService(UserService).setDelegatesApiV1DelegatesPost(ldapArray);

    set(loggedInEmployeeAtom, (draft) => {
      draft.delegates = updatedDelegates;
    });
  }
);
