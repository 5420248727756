/* eslint-disable no-param-reassign */
/*

- Update to team selection when clicking employee team
- Add chips + "organization" label
- Make nodes smaller for 3 column
- Keep all selected nodes expanded

*/

import {EmployeeWithHierarchy, TeamWithHierarchy} from 'client';
import * as d3 from 'd3';
import {getBackendUrl, getProfileImageUrl} from 'utilities';

import styles from './Chart.module.css';

export const nodeSize = (expanded?: boolean) =>
  expanded ? {width: 284, height: 72} : {width: 244, height: 72};
export const padding = 24;
const maxNodesForTwoColumn = 6;

export type HierarchyData = EmployeeWithHierarchy | TeamWithHierarchy;

export const isEmployee = (data: HierarchyData): data is EmployeeWithHierarchy => {
  return (data as EmployeeWithHierarchy).ldap !== undefined;
};

export const getUid = (data: HierarchyData): string => {
  return isEmployee(data) ? data.ldap : (data.slug ?? '');
};

export const getTitle = (data: HierarchyData): string =>
  isEmployee(data) ? data.name : (data.name ?? '');

export const getSubtitle = (data: HierarchyData): string =>
  isEmployee(data)
    ? (data.role ?? '') + (data.level ? ` (${data.level})` : '')
    : (data.primary_contact ?? 'No primary contact');

export const findPathToRoot = (
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  selection: string
): d3.HierarchyPointNode<HierarchyData>[] => {
  const selectedNode = nodes.find((node) => getUid(node.data) === selection);
  const pathToRoot: d3.HierarchyPointNode<HierarchyData>[] = [];

  if (selectedNode) {
    let currentNode: d3.HierarchyPointNode<HierarchyData> | null = selectedNode;
    while (currentNode) {
      pathToRoot.push(currentNode);
      currentNode = currentNode.parent;
    }
  }

  return pathToRoot;
};

export const renderLinks = (
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  links: d3.HierarchyPointLink<HierarchyData>[],
  pathToRoot: d3.HierarchyPointNode<HierarchyData>[],
  maxDepth: number,
  expanded: boolean
) => {
  const isInPath = (link: d3.HierarchyPointLink<HierarchyData>) => {
    return pathToRoot.includes(link.target as d3.HierarchyPointNode<HierarchyData>);
  };

  const isDeepestLevelLink = (link: d3.HierarchyPointLink<HierarchyData>) => {
    return link.source.children?.every((child) => child.depth === maxDepth) ?? false;
  };

  const deepestLevelLinks = links.filter(isDeepestLevelLink);
  const otherLinks = links.filter((d) => !isDeepestLevelLink(d));

  // Render deepest level links with customized paths
  g.selectAll<SVGPathElement, d3.HierarchyPointLink<HierarchyData>>('path.deepest-level-link')
    .data(deepestLevelLinks)
    .enter()
    .append('path')
    .attr('class', (d) =>
      isInPath(d) ? 'highlighted-link deepest-level-link' : 'regular-link deepest-level-link'
    )
    .attr('fill', 'none')
    .attr('stroke', (d) => (isInPath(d) ? 'var(--dig-color__primary__base)' : '#ccc'))
    .attr('stroke-width', (d) => (isInPath(d) ? 2 : 1))
    .attr('d', (d) => {
      const sourceX = d.source.x!;
      const sourceY = d.source.y!;
      const targetX = d.target.x!;
      const targetY = d.target.y!;

      const isOnlyOneChild = (d.source.children?.length ?? 0) === 1;
      if (isOnlyOneChild) {
        return `
          M${sourceX},${sourceY}
          V${targetY}
        `;
      }

      // Determine if this link belongs to a 3-column layout
      const isThreeColumnLayout = (d.source.children?.length ?? 0) > maxNodesForTwoColumn;
      if (isThreeColumnLayout) {
        const midY = sourceY + 40; // Middle point for the horizontal line below the source
        const leftX = sourceX - nodeSize(expanded).width / 2 - padding / 2;
        const rightX = sourceX + nodeSize(expanded).width / 2 + padding / 2;

        if (targetX <= sourceX) {
          // Target is in the left column
          return `
            M${sourceX},${sourceY}
            V${midY + 10}
            H${leftX}
            V${targetY}
            H${targetX}
          `;
        } else if (targetX > sourceX) {
          // Target is in the right column
          return `
            M${sourceX},${sourceY}
            V${midY + 10}
            H${rightX}
            V${targetY}
            H${targetX}
          `;
        }
      }

      // For standard 2-column
      return `
        M${sourceX},${sourceY}
        V${targetY}
        H${targetX}
      `;
    });

  // Render other links with default paths
  g.selectAll<SVGPathElement, d3.HierarchyPointLink<HierarchyData>>('path.other-link')
    .data(otherLinks)
    .enter()
    .append('path')
    .attr('class', (d) => (isInPath(d) ? 'highlighted-link other-link' : 'regular-link other-link'))
    .attr('fill', 'none')
    .attr('stroke', (d) => (isInPath(d) ? 'var(--dig-color__primary__base)' : '#ccc'))
    .attr('stroke-width', (d) => (isInPath(d) ? 2 : 1))
    .attr('d', (d) => {
      const verticalGap = padding * 2;
      return `
        M${d.source.x},${d.source.y}
        V${d.source.y + verticalGap}
        H${d.target.x}
        V${d.target.y}
      `;
    });

  // Ensure highlighted paths are always on top
  g.selectAll('.highlighted-link').raise();
};

export const renderStyledNodes = (
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  focusedNode: d3.HierarchyPointNode<HierarchyData> | undefined,
  selectedNode: d3.HierarchyPointNode<HierarchyData> | undefined,
  onClick: (event: MouseEvent, employee: string) => void,
  expanded: boolean
) => {
  g.append('defs')
    .append('clipPath')
    .attr('id', 'org-avatar-clip-path')
    .attr('clipPathUnits', 'objectBoundingBox')
    .append('path')
    .attr(
      'd',
      'M0.5,0 C0.164,0,0,0.164,0,0.5 C0,0.836,0.164,1,0.5,1 C0.836,1,1,0.836,1,0.5 C1,0.164,0.836,0,0.5,0'
    );

  // Create a container for each node
  const nodeGroup = g
    .selectAll<SVGGElement, d3.HierarchyPointNode<HierarchyData>>('.node-container')
    .data(nodes)
    .enter()
    .append('foreignObject')
    .attr('class', 'node-container')
    .attr('x', (d) => (d.x ?? 0) - nodeSize(expanded).width / 2)
    .attr('y', (d) => (d.y ?? 0) - nodeSize(expanded).height / 2)
    .attr('width', nodeSize(expanded).width)
    .attr('height', nodeSize(expanded).height)
    .style('overflow', 'visible');

  // Append content inside each node box
  const nodeCard = nodeGroup
    .append('xhtml:a')
    .on('click', (e, d) => onClick(e, getUid(d.data)))
    .attr('href', (d) => `/${isEmployee(d.data) ? 'people' : 'teams'}/` + getUid(d.data))
    .attr(
      'class',
      (d) =>
        styles.nodeCard +
        (d === focusedNode ? ` ${styles.focused}` : '') +
        (d === selectedNode ? ` ${styles.selected}` : '')
    )
    .html((d) =>
      isEmployee(d.data)
        ? ` 
      <svg width="40" height="40" style="clip-path: url(#org-avatar-clip-path); height: 100%; margin: 0; flex-shrink: 0">
        <image
          href="${getBackendUrl() + getProfileImageUrl(getUid(d.data))}"
          style="height: auto; width: 100%"
        />
      </svg>`
        : ''
    );

  // Add the content
  nodeCard
    .append('div')
    .attr('class', styles.nodeCardContent)
    .html(
      (d) => `
      <div style="font-weight:bold; font-size: 14px; line-height: 22px; color: var(--dig-color__text__base); white-space: nowrap;text-overflow: ellipsis; overflow: hidden;">${getTitle(d.data)}</div>
      <div style="font-size: 12px; line-height: 20px; color: var(--dig-color__text__subtle);white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${getSubtitle(d.data) ?? ''}</div>`
    );
};

export const centerOnFocusedNode = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>,
  width: number,
  height: number,
  centerNode?: d3.HierarchyPointNode<HierarchyData>,
  expanded?: boolean
) => {
  let centerOffsetX;

  if (centerNode) {
    centerOffsetX = width / 2 - (centerNode.x! - 40);
  } else {
    centerOffsetX = width / 2;
  }

  const newTransform = d3.zoomIdentity.translate(
    centerOffsetX,
    Math.min(nodeSize(expanded).height / 2 + 16 + (expanded ? 100 : 0), height * 0.2)
  );
  svg.call(zoom.transform, newTransform);
};

const isDeepestLevelChildren = (maxDepth: number) => (node: d3.HierarchyPointNode<HierarchyData>) =>
  node.children?.every((child) => child.depth === maxDepth) ?? false;

export function adjustDeepestNodes(
  nodes: d3.HierarchyPointNode<HierarchyData>[],
  maxDepth: number,
  expanded: boolean
) {
  const deepestLevelNodes = nodes.filter(isDeepestLevelChildren(maxDepth));

  deepestLevelNodes.forEach((deepNode) => {
    if (deepNode && deepNode.children) {
      const horizontalSpacing = nodeSize(expanded).width + padding;
      const verticalSpacing = nodeSize(expanded).height + padding;
      const columnCount = deepNode.children.length > maxNodesForTwoColumn ? 3 : 2;
      const centerOffset = ((columnCount - 1) / 2) * horizontalSpacing;

      if (deepNode.children.length === 1) {
        return;
      }

      deepNode.children.forEach((child, index) => {
        const col = index % columnCount;
        const row = Math.floor(index / columnCount);

        const desiredX = deepNode.x! + (col * horizontalSpacing - centerOffset);
        const desiredY = deepNode.y! + (row + 1) * verticalSpacing;

        const shiftX = desiredX - child.x!;
        const shiftY = desiredY - child.y!;

        shiftSubtree(child, shiftX, shiftY);
      });
    }
  });
}

function shiftSubtree(node: d3.HierarchyPointNode<HierarchyData>, shiftX: number, shiftY: number) {
  node.x! += shiftX;
  node.y! += shiftY;
  if (node.children) {
    node.children.forEach((child) => {
      shiftSubtree(child, shiftX, shiftY);
    });
  }
}

export function adjustSiblingPositionsForVerticalAlignment(
  node: d3.HierarchyPointNode<HierarchyData>
) {
  const siblings = node.parent?.children ?? [];

  const siblingCenters = siblings.map((sibling) => sibling.x!);
  const averageX = d3.mean(siblingCenters)!;

  // Adjust siblings to maintain proper spacing
  siblings.forEach((sibling) => {
    if (sibling !== node) {
      const shiftX = averageX - sibling.x!;
      shiftSubtree(sibling, shiftX, 0); // Keep the sibling subtree in place
    }
  });
}

export function alignPathToRoot(
  selectedNode: d3.HierarchyPointNode<HierarchyData>,
  expanded: boolean
) {
  const pathToRoot: d3.HierarchyPointNode<HierarchyData>[] = [];
  let currentNode: d3.HierarchyPointNode<HierarchyData> | null = selectedNode;
  while (currentNode) {
    pathToRoot.unshift(currentNode);
    currentNode = currentNode.parent;
  }

  const targetX = selectedNode.x!; // The x-coordinate we want to align to

  // Traverse from root to selected node
  for (let i = 0; i < pathToRoot.length - 1; i++) {
    const parentNode = pathToRoot[i];
    const childNode = pathToRoot[i + 1];

    // Calculate the shift needed to align the parent over the targetX
    const shiftX = targetX - parentNode.x!;

    parentNode.x! += shiftX;

    // Shift siblings to avoid overlaps with the vertical path
    const siblings = parentNode.children || [];
    siblings.forEach((sibling) => {
      if (sibling === childNode) return;

      let requiredShift = 0;
      if (sibling.x! < parentNode.x!) {
        // Sibling is on the left
        const overlap =
          parentNode.x! -
          nodeSize(expanded).width / 2 -
          padding -
          (sibling.x! + nodeSize(expanded).width / 2);
        if (overlap < 0) {
          requiredShift = overlap;
        }
      } else {
        // Sibling is on the right
        const overlap =
          sibling.x! -
          nodeSize(expanded).width / 2 -
          (parentNode.x! + nodeSize(expanded).width / 2 + padding);
        if (overlap < 0) {
          requiredShift = -overlap;
        }
      }

      // Shift the sibling subtree if necessary
      if (requiredShift !== 0) {
        shiftSubtree(sibling, requiredShift, 0);
      }
    });

    // After shifting siblings, resolve any overlaps among them
    resolveSiblingOverlaps(parentNode, expanded);
  }
}

function resolveSiblingOverlaps(
  parentNode: d3.HierarchyPointNode<HierarchyData>,
  expanded: boolean
) {
  const siblings = parentNode.children?.filter((child) => child !== parentNode) || [];
  siblings.sort((a, b) => (a.x! < b.x! ? -1 : 1));

  for (let i = 1; i < siblings.length; i++) {
    const leftSibling = siblings[i - 1];
    const rightSibling = siblings[i];
    const overlap =
      leftSibling.x! +
      nodeSize(expanded).width / 2 +
      padding -
      (rightSibling.x! - nodeSize(expanded).width / 2);

    if (overlap > 0) {
      shiftSubtree(rightSibling, overlap, 0);
    }
  }
}
