import {Button} from '@dropbox/dig-components/dist/buttons';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {BriefcaseLine, CheckmarkLine, FollowLine} from '@dropbox/dig-icons/assets';
import {useMutation, useSuspenseQuery} from '@tanstack/react-query';
import {isMobileAtom} from 'atoms/layout';
import {snackbarAtom} from 'atoms/snackbar';
import {ProjectService} from 'client';
import {Title} from 'components/DSYS/Title';
import {ProjectStatusButton} from 'components/projects/ProjectStatusButton';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

const FollowButton = ({isProject, id, title}: {isProject: boolean; title?: string; id: string}) => {
  const [clickedFollow, setClickedFollow] = useState(false);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {data, isLoading} = useSuspenseQuery({
    queryKey: ['follow', id],
    queryFn: () => getService(ProjectService).followingApiV1ProjectsIdentifierFollowingGet(id),
  });
  const {mutate: subscribe} = useMutation({
    mutationFn: () => getService(ProjectService).followApiV1ProjectsIdentifierFollowPost(id),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['follow']}),
  });
  const {mutate: unsubscribe} = useMutation({
    mutationFn: () => getService(ProjectService).unfollowApiV1ProjectsIdentifierUnfollowPost(id),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['follow']}),
  });

  useEffect(() => {
    if (clickedFollow) {
      setTimeout(() => setClickedFollow(false), 3000);
    }
  }, [clickedFollow]);

  return (
    <Tooltip
      title={
        data.team
          ? t('project_unfollow_tooltip_error', {area: data.team?.name})
          : !data.following
          ? t('project_follow_tooltip')
          : t('project_unfollow_tooltip')
      }
    >
      <Box>
        <Button
          variant="outline"
          onClick={() => {
            setClickedFollow(!data.following);
            data.following ? unsubscribe() : subscribe();

            if (data.following) {
              setSnackbarMessage({
                text: (
                  <Trans
                    i18nKey="follow_tooltip_unfollow"
                    t={t}
                    values={{title}}
                    components={{b: <Text isBold />}}
                  />
                ),
              });
            } else {
              setSnackbarMessage({
                text: (
                  <Trans
                    i18nKey={
                      isProject ? 'follow_tooltip_follow_project' : 'follow_tooltip_follow_team'
                    }
                    t={t}
                    values={{title}}
                    components={{b: <Text isBold />}}
                  />
                ),
              });
            }
          }}
          isLoading={isLoading}
          disabled={Boolean(data.team)}
          withIconStart={<UIIcon src={clickedFollow ? CheckmarkLine : FollowLine} />}
        >
          {data.following ? 'Following' : 'Follow'}
        </Button>
      </Box>
    </Tooltip>
  );
};

export const ProjectCard = ({
  isProfile,
  title,
  subtitle,
  projectId,
  slug,
  onStatusClick,
}: {
  isProfile?: boolean;
  title?: string;
  subtitle?: string;
  slug?: string;
  projectId?: number;
  onStatusClick?: (id: number) => void;
}) => {
  const isMobile = useAtomValue(isMobileAtom);
  return (
    <Split gap="8" direction={!isMobile || isProfile ? 'horizontal' : 'vertical'}>
      {isProfile && (
        <Split.Item>
          <Box as={UIIcon} color="Text Subtle" src={BriefcaseLine} />
        </Split.Item>
      )}
      <Split.Item width="fill" style={{maxWidth: !isProfile ? 856 : 'inherit'}}>
        <Stack gap="4">
          {title ? (
            isProfile ? (
              <Text>{title}</Text>
            ) : (
              <Box as={Title} size={24} marginBottom={subtitle ? '8' : undefined}>
                {title}
              </Box>
            )
          ) : (
            <Skeleton.Box width={320} height={30} />
          )}

          {subtitle !== undefined ? (
            <Text size={!isProfile ? 'large' : 'small'} color="faint">
              {isProfile ? <Truncate lines={2}>{subtitle}</Truncate> : subtitle}
            </Text>
          ) : (
            <Skeleton.Box height={72} style={{width: '100%'}} />
          )}
        </Stack>
      </Split.Item>
      {projectId && (
        <Split.Item
          paddingLeft={isProfile ? '8' : !isMobile ? '44' : undefined}
          style={{
            width: isProfile ? 120 : undefined,
            marginLeft: isMobile ? undefined : 'auto',
            marginRight: !isMobile ? undefined : 'auto',
          }}
        >
          <ProjectStatusButton
            size={isProfile ? 'small' : 'large'}
            projectId={projectId}
            fullWidth={!isProfile}
            onClick={() => onStatusClick?.(projectId)}
          />
        </Split.Item>
      )}
      {!isProfile && (slug || projectId) && (
        <Split.Item
          paddingLeft={projectId ? undefined : '44'}
          style={{marginLeft: projectId ? '8' : 'auto'}}
        >
          <FollowButton
            id={(projectId ?? slug)!.toString()}
            isProject={Boolean(projectId)}
            title={title}
          />
        </Split.Item>
      )}
    </Split>
  );
};
