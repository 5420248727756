import {confettiTrackerAtom} from 'atoms/confetti';
import {useAtom} from 'jotai';
import {useEffect, useState} from 'react';
import Confetti from 'react-confetti';

const useWindowSize = () => {
  // State for storing window width and height
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handler = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Set up event listener
    window.addEventListener('resize', handler);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []); // Empty array ensures effect is only run on mount and unmount

  return dimensions;
};

function drawAnniversary(this: any, ctx: CanvasRenderingContext2D) {
  const confettiType = this.confettiType ?? Math.floor(Math.random() * 4);
  const w = this.w ?? 12;
  const h = this.h ?? 12;
  this.confettiType = confettiType;
  this.w = w;
  this.h = h;

  switch (confettiType) {
    case 0:
      return drawCircle(ctx, w / 2);
    case 1:
      return drawRect(ctx, w, h);
    case 2:
      return drawStrip(ctx, w, h);
    case 3:
      return drawLogo(ctx);
  }
}

function drawBirthday(this: any, ctx: CanvasRenderingContext2D) {
  const confettiType = this.confettiType ?? Math.floor(Math.random() * 3);
  const w = this.w ?? 12;
  const h = this.h ?? 12;
  this.confettiType = confettiType;
  this.w = w;
  this.h = h;

  switch (confettiType) {
    case 0:
      return drawCircle(ctx, w / 2);
    case 1:
      return drawRect(ctx, w, h);
    case 2:
      return drawStrip(ctx, w, h);
  }
}

function drawLogo(ctx: CanvasRenderingContext2D) {
  ctx.save();
  const scale = 0.65;
  ctx.scale(scale, scale);
  ctx.translate(-21.9462 / scale, -15.0002 / scale);
  ctx.fill(
    new Path2D(
      `M21.9462 15.0002L11.8902 21.1817L21.9462 27.3632L11.8902 33.5446L21.9462 39.7261L32.0022 33.5446L42.0583 39.7261L52.1143 33.5446L42.0583 27.3632L52.1143 21.267L42.0583 15.0002L32.0022 21.1817L21.9462 15.0002ZM32.0022 21.1817L42.0583 27.3632L32.0022 33.5446L21.9462 27.3632L32.0022 21.1817Z`
    )
  );
  ctx.fill(
    new Path2D(
      `M21.9462 42.8188L32.0022 36.6373L37.0303 39.7281L42.0583 42.8188L32.0022 49.0002L21.9462 42.8188Z`
    )
  );
  ctx.restore();
}

function drawCircle(ctx: CanvasRenderingContext2D, radius: number) {
  ctx.beginPath();
  ctx.arc(0, 0, radius, 0, 2 * Math.PI);
  ctx.fill();
}

function drawRect(ctx: CanvasRenderingContext2D, w: number, h: number) {
  ctx.fillRect(-w / 2, -h / 2, w, h);
}

function drawStrip(ctx: CanvasRenderingContext2D, w: number, h: number) {
  ctx.fillRect(-w / 6, -h / 2, w / 3, h);
}

export const ProfileConfetti = ({ldap, type}: {ldap: string; type: 'birthday' | 'anniversary'}) => {
  const {width, height} = useWindowSize();
  const [confettiTracker, setConfettiTracker] = useAtom(confettiTrackerAtom);
  const [isRecycling, setIsRecycling] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(true);
    setIsRecycling(true);
    const timerId = setTimeout(() => {
      setIsRecycling(false);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [ldap]);

  useEffect(() => {
    if (!confettiTracker[ldap]) {
      setIsDisabled(false);
      setConfettiTracker({...confettiTracker, [ldap]: true});
    }
  }, [ldap, confettiTracker, setConfettiTracker]);

  if (isDisabled) {
    return null;
  }

  return (
    <>
      {/* <Confetti
        width={width}
        height={height}
        recycle={isRecycling}
        numberOfPieces={50}
        gravity={0.2}
        confettiSource={{x: width * 0.01, y: height * 0.01, w: 0, h: 0}}
        initialVelocityX={{min: width * 0.0, max: width * 0.0055}}
        initialVelocityY={{min: height * 0.015, max: 0}}
        drawShape={type === 'anniversary' ? drawAnniversary : drawBirthday}
        {...(type === 'anniversary' ? {colors: ['#1A1918', '#0061FE', '#ffffff']} : {})}
      /> */}
      <Confetti
        width={width}
        height={height}
        recycle={isRecycling}
        numberOfPieces={150}
        gravity={0.2}
        confettiSource={{x: width * 0.5, y: height * 0.3, w: 0, h: 0}}
        initialVelocityX={{min: -width * 0.005, max: width * 0.005}}
        initialVelocityY={{min: -height * 0.01, max: 0}}
        drawShape={type === 'anniversary' ? drawAnniversary : drawBirthday}
        {...(type === 'anniversary' ? {colors: ['#1A1918', '#0061FE', '#ffffff']} : {})}
      />
      {/* <Confetti
        width={width}
        height={height}
        recycle={isRecycling}
        numberOfPieces={50}
        gravity={0.2}
        confettiSource={{x: width * 0.99, y: height * 0.01, w: 0, h: 0}}
        initialVelocityX={{min: -width * 0.0055, max: -width * 0.001}}
        initialVelocityY={{min: height * 0.015, max: 0}}
        drawShape={type === 'anniversary' ? drawAnniversary : drawBirthday}
        {...(type === 'anniversary' ? {colors: ['#1A1918', '#0061FE', '#ffffff']} : {})}
      /> */}
    </>
  );
};
