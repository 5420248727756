export enum CurrentEnvironment {
  Development,
  Amplify,
  Staging,
  Production,
}

const hostname = window.location.hostname;

export const getCurrentEnvironment: () => CurrentEnvironment = () => {
  if (hostname.includes('localhost') || hostname.includes('127.0.0.1')) {
    return CurrentEnvironment.Development;
  }

  if (hostname.includes('dropboxpulse') || hostname.includes('amplifyapp')) {
    return CurrentEnvironment.Staging;
  }

  return CurrentEnvironment.Production;
};

export const isDev = getCurrentEnvironment() === CurrentEnvironment.Development;

export const isProd = getCurrentEnvironment() === CurrentEnvironment.Production;
