import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine, SearchLine, TeamLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Team as TeamSearchResult, TeamWithCounts} from 'client';
import {AvatarIcon} from 'components/DSYS/Avatar';
import {Title} from 'components/DSYS/Title';
import {TeamMembersAndSubTeamsCounts} from 'components/shared/TeamMembersAndSubTeamsCounts';
import {useHierarchy} from 'components/teams/hooks';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {useRef, useState} from 'react';
import {DASHBOARDS_DROPBOX_NAME, DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';

import styles from './Dashboards.module.css';

export const recentDashboardsTeamSearchesAtom = atomWithStorage(
  'recentDashboardsTeamSearches',
  [] as DashboardsTeamSearchInfo[]
);

const MAX_RECENT_SEARCHES_TO_DISPLAY = 4;

export const DashboardsTeamNav = ({
  teamName,
  numMembers,
  numSubTeams,
  navigateToTeamDashboard,
  teamSlug,
  icon,
  employeeTeamSlug,
}: {
  teamName: string;
  numMembers: number;
  numSubTeams: number;
  navigateToTeamDashboard: (teamSlug: string) => void;
  teamSlug?: string;
  icon?: React.JSX.Element;
  employeeTeamSlug?: string;
}) => {
  return (
    <Box display="flex" alignItems="center">
      {icon ? (
        icon
      ) : (
        <AvatarIcon
          src={TeamLine}
          size="large"
          background="var(--dig-color__opacity__surface)"
          color="Text Subtle"
        />
      )}
      <Box marginLeft="16">
        <Box display="flex" alignItems="center">
          <Title size={24}>{teamName}</Title>
          <DashboardsTeamSearch
            handleSelection={navigateToTeamDashboard}
            employeeTeamSlug={employeeTeamSlug}
            teamSlug={teamSlug}
          />
        </Box>
        <TeamMembersAndSubTeamsCounts
          membersCount={numMembers}
          subTeamsCount={numSubTeams}
          marginTop={icon ? '5px' : '3px'}
          hideZeroCounts
        />
      </Box>
    </Box>
  );
};

interface DashboardsTeamSearchInfo {
  slug: string;
  name: string;
  members_count?: number;
  subteams_count?: number;
}

export const DashboardsTeamSearch = ({
  handleSelection,
  employeeTeamSlug,
  teamSlug,
}: {
  handleSelection: (teamSlug: string) => void;
  employeeTeamSlug?: string;
  teamSlug?: string;
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputContainerRef = useRef<HTMLElement | null>(null);
  const header = document.querySelector('.dig-GlobalHeader') as HTMLElement | null;

  const employeeTeam = useHierarchy({slug: employeeTeamSlug});
  const employeeTeamHierarchy = employeeTeam?.hierarchy ?? [];

  const [recentTeamSearches, setRecentTeamSearches] = useAtom(recentDashboardsTeamSearchesAtom);

  const handleSetRecentTeamSearches = (newRecentTeamSearches: DashboardsTeamSearchInfo[]) => {
    const uniqueTeamSearches = Object.values(
      newRecentTeamSearches.reduce(
        (acc, item) => ({
          ...acc,
          [item.slug ?? '']: item,
        }),
        {} as Record<string, DashboardsTeamSearchInfo>
      )
    ).slice(0, MAX_RECENT_SEARCHES_TO_DISPLAY);
    setRecentTeamSearches(uniqueTeamSearches);
  };

  const searchedTeamResults = useGlobalSearch({
    input: inputValue,
    filter: 'teams',
    limit: 50,
    showMore: true,
  });
  const searchedTeams = inputValue.length
    ? searchedTeamResults
        .map((result) => result as TeamSearchResult)
        .filter((team) => team.slug !== teamSlug)
    : [];

  const renderEmployeeTeamChips = () => {
    if (!employeeTeamHierarchy.length) {
      return null;
    }

    const teamChips: DashboardsTeamSearchInfo[] = [
      ...employeeTeamHierarchy
        .filter((team) => team.slug !== teamSlug)
        .map((team) => ({
          slug: team.slug!,
          name: team.name!,
        })),
      {slug: DASHBOARDS_DROPBOX_TEAM_SLUG, name: DASHBOARDS_DROPBOX_NAME},
    ];
    return (
      <Box as={Split} marginTop="12" style={{gap: '4px'}}>
        {teamChips.map((team) => (
          <Chip
            key={`dashboards-team-nav-search-chip__${team.slug}`}
            size="small"
            onClick={() => {
              analyticsLogger().logEvent('DASHBOARD_TEAM_NAV_SELECTED', {type: 'default'});
              handleSelection(team.slug ?? '');
              setInputValue('');
              handleSetRecentTeamSearches([team, ...recentTeamSearches]);
              header?.click(); // close menu after selection
            }}
          >
            <Chip.AvatarAccessory>
              <AvatarIcon
                src={TeamLine}
                size="small"
                background="var(--dig-color__opacity__surface-state-1)"
              />
            </Chip.AvatarAccessory>
            <Chip.Content>{team.name}</Chip.Content>
          </Chip>
        ))}
      </Box>
    );
  };

  const renderRecentTeamSearches = () => {
    if (!recentTeamSearches.length) {
      return null;
    }
    return (
      <>
        <Box paddingBottom="16" marginBottom="4" borderColor="Border Subtle" borderBottom="Solid" />
        <Typeahead.Results
          title={t('recent')}
          results={recentTeamSearches}
          renderRow={(row) => renderTypeaheadRow('recent', row, {borderRadius: '8px'})}
        />
      </>
    );
  };

  const renderTeamSubtitle = (team: TeamWithCounts | DashboardsTeamSearchInfo) => {
    if (team.members_count === undefined || team.subteams_count === undefined) {
      return;
    }
    return (
      <TeamMembersAndSubTeamsCounts
        membersCount={team.members_count ?? 0}
        subTeamsCount={team.subteams_count ?? 0}
      />
    );
  };

  const renderTypeaheadRow = (
    type: string,
    team: TeamWithCounts | DashboardsTeamSearchInfo,
    style?: {[key: string]: string}
  ) => {
    return (
      <Typeahead.Row
        value={team}
        withTitle={
          <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
            {team.name}
          </Box>
        }
        withSubtitle={renderTeamSubtitle(team)}
        style={style}
        onClick={() => {
          analyticsLogger().logEvent('DASHBOARD_TEAM_NAV_SELECTED', {type});
          header?.click(); // close menu after selection
        }}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <Menu.Wrapper>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box as="div" display="flex" marginLeft="6">
            <Box as={IconButton} {...getTriggerProps()} shape="circular" variant="transparent">
              <UIIcon src={ChevronDownLine} />
            </Box>
          </Box>
          <Menu.Content {...getContentProps()} placement="right-start" triggerOffset={10}>
            <Box className={styles.dashboardsTeamNavMenu}>
              <Typeahead.Wrapper
                onSelection={(team: TeamSearchResult) => {
                  const newRecentTeamSearches = [team, ...recentTeamSearches].map((recent) => ({
                    slug: recent.slug ?? '',
                    name: recent.name ?? '',
                  }));
                  handleSelection(team.slug ?? '');
                  setInputValue('');
                  handleSetRecentTeamSearches(newRecentTeamSearches);
                }}
                closeOnSelection
              >
                {({
                  getContentProps: getTypeaheadContentProps,
                  getTriggerProps: getTypeaheadTriggerProps,
                }) => (
                  <>
                    <TextInput.Container ref={inputContainerRef}>
                      <TextInput.Accessory>
                        <UIIcon src={SearchLine} />
                      </TextInput.Accessory>
                      <TextInput.Input
                        placeholder={t('switch_teams')}
                        value={inputValue}
                        {...getTypeaheadTriggerProps({
                          onChange: onInputChange,
                        })}
                      />
                    </TextInput.Container>
                    {renderEmployeeTeamChips()}
                    {renderRecentTeamSearches()}
                    <Typeahead.Container
                      {...getTypeaheadContentProps()}
                      triggerRef={inputContainerRef}
                    >
                      {searchedTeams.length ? (
                        <Typeahead.Results
                          results={searchedTeams}
                          renderRow={(row) => renderTypeaheadRow('search', row, {})}
                          initialResults={5}
                          maxResults={10}
                        />
                      ) : inputValue.length ? (
                        <Typeahead.Results results={[{}]} renderRow={renderNoResultsRow} />
                      ) : null}
                    </Typeahead.Container>
                  </>
                )}
              </Typeahead.Wrapper>
            </Box>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
