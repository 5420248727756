import {Select, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Split} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';

const audience = ['Company', 'Managers', 'RequiredOKRs', 'Admins' /**, LDAPs */];

export const AudienceSelect = ({
  error,
  value,
  onSelect,
}: {
  error?: boolean;
  value?: string;
  onSelect: (type: string) => void;
}) => {
  const user = useAtomValue(pulseUserAtom);
  const [useLdap, setUseLdap] = useState(false);
  const handleSelection = (type: string) => {
    if (type === 'ldaps') {
      setUseLdap(true);
    } else {
      setUseLdap(false);
      onSelect(type);
    }
  };

  return (
    <Split direction="vertical">
      <Split.Item>
        <Select
          value={value}
          id="audience"
          placeholder="— Select —"
          onChange={handleSelection}
          isInvalid={error && !value}
        >
          {audience.map((option) => (
            <Select.Option key={option} value={option}>
              {t(`notification_schedule_${option}`)}
            </Select.Option>
          ))}
        </Select>
      </Split.Item>
      <Split.Item>
        {useLdap && (
          <TextInput
            placeholder="List of ldaps"
            value={user?.email.split('@')[0]}
            onChange={(e) => onSelect(e.currentTarget.value)}
          />
        )}
      </Split.Item>
    </Split>
  );
};
