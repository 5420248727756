import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getBackendUrl} from 'utilities';

import styles from './BadgeImage.module.css';

export const BadgeImage = ({
  badge,
  size = 100,
  hideSkeleton = false,
}: {
  badge: string;
  size?: 40 | 64 | 100 | 48 | 24;
  hideSkeleton?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState(getBackendUrl() + `/api/v1/badges/images/${badge}`);

  useEffect(() => {
    setUrl(getBackendUrl() + `/api/v1/badges/images/${badge}`);
  }, [badge]);

  return (
    <div className={styles.container} style={{height: size, width: size}}>
      <LazyLoadImage
        className={styles.image}
        height={size}
        width={size}
        placeholder={<Skeleton.Box height={size} width={size} />}
        src={url}
        afterLoad={() => {
          // Should not be necessary, but based on the following issue, it is a known problem

          setLoaded(true);
        }}
      />
      {!hideSkeleton && (
        <Skeleton.Box
          className={styles.skeleton}
          style={{
            visibility: !loaded ? 'visible' : 'hidden',
            opacity: !loaded ? '1' : '0',
          }}
        />
      )}
    </div>
  );
};
