import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, withShade} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Link} from 'components/DSYS/Link';
import {ReportCountBadge} from 'components/shared/ReportCountBadge';
import {t} from 'i18next';
import {Fragment, FunctionComponent} from 'react';

import styles from './ProfileReportingTree.module.css';

interface ProfileReportingTreeProps {
  reportingLine: Employee[];
}

export const ProfileReportingTree: FunctionComponent<ProfileReportingTreeProps> = ({
  reportingLine,
}) => {
  return (
    <div className={styles.profileReportingTreeSection}>
      <Text isBold size="xlarge">
        {t('reporting_line')}
      </Text>
      <div className={styles.profileReportingTreeList}>
        {reportingLine.map((person, index) => (
          <Fragment key={`${person.ldap}_${index}`}>
            <Link
              to={'/people/' + person.ldap}
              state={{source: 'reporting'}}
              hasNoUnderline
              onClick={() =>
                analyticsLogger().logEvent('PROFILE_INTERACTION', {action: 'reporting'})
              }
              {...withShade({
                className: styles.profileReportingCard,
              })}
            >
              <Box position="relative">
                <Avatar user={person} size="xlarge" isInactive={person.is_deactivated || false} />
                {Boolean(person.direct_reports_count ?? 0 > 0) && (
                  <ReportCountBadge
                    direct_reports_count={person.direct_reports_count ?? 0}
                    total_report_count={person.total_report_count ?? 0}
                  />
                )}
              </Box>
              <div className={styles.profileReportingCardDetails}>
                <Text isBold>{person.name}</Text>
                <Text size="small" color="faint">
                  {person.role}
                </Text>
              </div>
            </Link>
            {index !== reportingLine.length - 1 && (
              <div className={styles.profileReportingTreeLine} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
