import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Badge} from 'client';
import {BadgeImage} from 'components/badges/BadgeImage';
import {FunctionComponent, useState} from 'react';

import {BadgeModal} from './BadgeModal';

interface Props {
  badge: Badge;
  collected?: number[];
}
export const BadgeItem: FunctionComponent<Props> = ({badge, collected}) => {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip title={badge.name} shouldCloseOnClick>
      <li>
        <div onClick={() => setOpen(true)}>
          <BadgeImage size={40} badge={badge.image_key} />
        </div>
        {open && (
          <BadgeModal
            badge={badge}
            onRequestClose={() => setOpen(false)}
            collected={collected}
            closeModal={() => setOpen(false)}
          />
        )}
      </li>
    </Tooltip>
  );
};
