import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService} from 'client';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {getService} from 'utilities';

import {Section} from './Section';

export const StocksSection = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['stocks'],
    queryFn: getService(HomeService).stockApiV1HomeStockGet,
  });

  const handleEventClick = () => analyticsLogger().logEvent('HOME_STOCK_CLICKED');

  return (
    <Section
      isLoading={isLoading}
      gap="0"
      content={
        <Box display="flex" justifyContent="space-between">
          <Text color="faint" size="small">
            Dropbox, Inc.
          </Text>
          {isLoading ? (
            <Box as={Skeleton.Text} style={{width: 80}} size="small" />
          ) : (
            Boolean(data) && (
              <Box
                as={Text}
                size="small"
                color={data.change_percent < 0 ? 'Alert On Surface' : 'Success Base - State 2'}
              >
                {data.change_percent < 0 ? data.change : `+${data.change.toFixed(2)}`} (
                {data.change_percent.toFixed(2)}
                %)
              </Box>
            )
          )}
        </Box>
      }
      withAccessoryEnd={
        isLoading ? (
          <Box as={Skeleton.Text} size="large" style={{width: 55}} />
        ) : (
          <Title size={16}>${data ? data.price.toFixed(2) : '-'}</Title>
        )
      }
      title={
        <Text isBold>
          <Link
            hasNoUnderline
            to="https://www.google.com/finance/quote/DBX:NASDAQ"
            onClick={handleEventClick}
          >
            <Title size={16}>
              DBX
              {Boolean(data) && (
                <Box
                  isBold
                  as={Text}
                  size="large"
                  color={data.change_percent < 0 ? 'Alert On Surface' : 'Success Base - State 2'}
                  paddingLeft="4"
                >
                  {data.change_percent < 0 ? '▼' : '▲'}
                </Box>
              )}
            </Title>
          </Link>
        </Text>
      }
    />
  );
};
