import {Chip} from '@dropbox/dig-components/dist/chip';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {InfoLine} from '@dropbox/dig-icons/assets';
import classNames from 'classnames';
import {EmployeeFull} from 'client';
import {Link} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {t} from 'i18next';
import {FunctionComponent, useCallback} from 'react';

import styles from './ProfileWorkingWithMe.module.css';

interface Props {
  employee: EmployeeFull;
  workingWithMe?: string | null;
}

export const ProfileWorkingWithMe: FunctionComponent<Props> = ({employee, workingWithMe}) => {
  const mapEnneagramData = useCallback(
    (value: string): {name: string; strengths: string} | undefined => {
      switch (value) {
        case '1': {
          return {name: t('enneagram_1_name'), strengths: t('enneagram_1_strenghts')};
        }
        case '2': {
          return {name: t('enneagram_2_name'), strengths: t('enneagram_2_strenghts')};
        }
        case '3': {
          return {name: t('enneagram_3_name'), strengths: t('enneagram_3_strenghts')};
        }
        case '4': {
          return {name: t('enneagram_4_name'), strengths: t('enneagram_4_strenghts')};
        }
        case '5': {
          return {name: t('enneagram_5_name'), strengths: t('enneagram_5_strenghts')};
        }
        case '6': {
          return {name: t('enneagram_6_name'), strengths: t('enneagram_6_strenghts')};
        }
        case '7': {
          return {name: t('enneagram_7_name'), strengths: t('enneagram_7_strenghts')};
        }
        case '8': {
          return {name: t('enneagram_8_name'), strengths: t('enneagram_8_strenghts')};
        }
        case '9': {
          return {name: t('enneagram_9_name'), strengths: t('enneagram_9_strenghts')};
        }
        default:
          return undefined;
      }
    },
    []
  );

  if (
    !workingWithMe &&
    !employee.ergs &&
    !(employee.enneagram && mapEnneagramData(employee.enneagram)) &&
    !employee.languages
  )
    return <></>;

  return (
    <div className={styles.container}>
      {workingWithMe && (
        <>
          <Text isBold size="xlarge">
            {t('working_with_me')}
          </Text>
          <div className={classNames(styles.about, styles.item)}>
            <Text isBold tagName="div">
              About {employee.name.split(' ')[0]}
            </Text>
            <RichTextArea theme="small-faint" value={workingWithMe} />
          </div>
        </>
      )}
      <div className={styles.items}>
        {employee.ergs && (
          <div className={styles.item}>
            <Text isBold>{t('resource_groups')}</Text>
            <div className={styles.chips}>
              {employee.ergs.split(',').map((erg) => {
                return (
                  <Chip size="small" key={erg}>
                    {erg}
                  </Chip>
                );
              })}
            </div>
          </div>
        )}
        {employee.languages && (
          <div className={styles.item}>
            <Text isBold>{t('languages')}</Text>
            <div className={styles.chips}>
              {employee.languages.split(',').map((language) => {
                return (
                  <Chip size="small" key={language}>
                    {language}
                  </Chip>
                );
              })}
            </div>
          </div>
        )}
        {employee.enneagram && mapEnneagramData(employee.enneagram) && (
          <div className={styles.item}>
            <div className={styles.enneagramTitle}>
              <Text isBold>{t('enneagram')}</Text>
              <Toggletip
                src={InfoLine}
                title={
                  <>
                    {t('enneagram_tooltip')}{' '}
                    <Link
                      monochromatic
                      target="_blank"
                      to="https://www.crystalknows.com/enneagram-test"
                    >
                      {t('enneagram_tooltip_link')}
                    </Link>
                  </>
                }
              />
            </div>
            <div className={styles.enneagram}>
              <Text size="xlarge" isBold>
                {employee.enneagram}
              </Text>
              <div>
                <Text size="small">{mapEnneagramData(employee.enneagram)?.name}</Text>
                <Text size="small" color="faint" tagName="div">
                  {mapEnneagramData(employee.enneagram)?.strengths}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
