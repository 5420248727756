import {Table} from '@dropbox/dig-components/dist/table';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Box, BoxProps, Split, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowDownLine, ArrowUpLine} from '@dropbox/dig-icons/assets';
import cx from 'classnames';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {t} from 'i18next';

import styles from './Table.module.css';

export type TableSort = {type: string; asc: boolean};
// foos
const SortableHeader = ({
  fixed,
  type,
  short,
  toggletip,
  sort,
  index,
  resizeHandler,
  setSort,
  dragging,
  width,
  minWidth,
  paddingLeft,
  paddingTop,
}: {
  type: string;
  sort?: TableSort;
  setSort: (value: TableSort) => void;
  index: number;
  resizeHandler: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  toggletip?: string;
  short?: string;
  dragging?: string;
  sortable?: boolean;
  width: number;
  fixed?: boolean;
  minWidth?: number;
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  paddingTop?: BoxProps<'div'>['paddingTop'];
}) => {
  const isDraggingSortType = dragging === type && sort?.type === type;
  let label = type;
  if (short && width && width < (isDraggingSortType ? 60 : 45)) {
    label = short;
  }
  return (
    <Box
      as={Table.HeaderCell}
      className={styles.headerCell}
      position="relative"
      paddingLeft={paddingLeft ? paddingLeft : '8'}
      paddingTop={paddingTop ? paddingTop : '8'}
    >
      <Box as={Split} alignY="center" style={{width, minWidth}}>
        <Box
          as={Split}
          cursor={isDraggingSortType ? 'pointer' : 'default'}
          alignY="center"
          userSelect="none"
          gap="8"
          onClick={() => setSort({type, asc: sort?.type === type ? !sort.asc : true})}
          width="100%"
        >
          <Box position="relative" display="flex">
            <Eyebrow color={sort?.type === type ? 'standard' : 'subtle'}>
              <OptionalTooltip
                enabled={width < (isDraggingSortType ? 60 : 45)}
                title={t(`project_table_${type}`)}
                openDelay={700}
              >
                <Box
                  as="div"
                  display="inline-block"
                  style={{
                    maxWidth: width - (isDraggingSortType ? 16 : 0),
                  }}
                >
                  <Truncate>{label}</Truncate>
                </Box>
              </OptionalTooltip>
            </Eyebrow>
            {toggletip && (
              <Toggletip
                title={toggletip}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
            <Box
              as={UIIcon}
              marginTop="4"
              size="small"
              position="absolute"
              color="Text Subtle"
              style={{right: -16}}
              src={sort?.asc ? ArrowDownLine : ArrowUpLine}
              visibility={sort?.type === type ? 'visible' : 'hidden'}
            />
          </Box>
        </Box>
      </Box>
      {!fixed && (
        <Box
          as="div"
          position="absolute"
          padding="4"
          visibility="hidden"
          userSelect="none"
          borderRadius="Medium"
          onMouseDown={resizeHandler(index)}
          {...withShade({
            className: cx(styles.resizeHandler, {
              [styles.isResizing]: dragging,
              [styles.showResizer]: dragging === type,
            }),
          })}
        >
          ||
        </Box>
      )}
    </Box>
  );
};
export const Header = ({
  columnConfigs,
  sort,
  setSort,
  dragging,
  getMouseDownHandler,
  className,
}: {
  columnConfigs: ColumnConfig[];
  sort?: TableSort;
  setSort: (value: TableSort) => void;
  dragging?: string;
  getMouseDownHandler: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}) => {
  return (
    <Box as={Table.Header} className={className}>
      <Box as={Table.Row}>
        {columnConfigs.map((config, index) => (
          <SortableHeader
            key={`${config.type}_${index}`}
            {...config}
            dragging={dragging}
            index={index}
            sort={sort}
            setSort={setSort}
            resizeHandler={getMouseDownHandler}
            paddingLeft={config.paddingLeft}
          />
        ))}
      </Box>
    </Box>
  );
};

export const StickyHeader = ({
  columnConfigs,
  sort,
  setSort,
  dragging,
  getMouseDownHandler,
}: {
  columnConfigs: ColumnConfig[];
  sort?: TableSort;
  setSort: (value: TableSort) => void;
  dragging?: string;
  getMouseDownHandler: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  return (
    <Box
      as={Table.Header}
      position="sticky"
      top="0"
      backgroundColor="Background Base"
      style={{zIndex: '1'}}
    >
      <Box as={Table.Row}>
        {columnConfigs.map((config, index) => (
          <SortableHeader
            key={`${config.type}_${index}`}
            {...config}
            dragging={dragging}
            index={index}
            sort={sort}
            setSort={setSort}
            resizeHandler={getMouseDownHandler}
            paddingLeft={config.paddingLeft}
            paddingTop={config.paddingTop}
          />
        ))}
      </Box>
    </Box>
  );
};
