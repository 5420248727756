import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  BriefcaseLine,
  CloseLine,
  EmailLine,
  MobileLine,
  SlackExternalLogo,
  TeamLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {EmployeeService} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {CircleIcon} from 'components/DSYS/CircleIcon';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Title} from 'components/DSYS/Title';
import {useTeam} from 'components/teams/hooks';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useCallback, useEffect} from 'react';
import {getService} from 'utilities';

export const ChartCard = ({
  children,
  expanded,
  selection,
  focus,
  onClose,
}: {
  children: React.ReactNode;
  expanded: boolean;
  focus: string;
  selection?: string;
  onClose: () => void;
}) => {
  useEffect(() => {
    if (!expanded) {
      onClose();
    }
  }, [expanded, onClose]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (selection && event.key === 'Escape') {
        if (focus !== selection) {
          event.preventDefault();
          event.stopPropagation();
        }
        onClose();
      }
    },
    [selection, focus, onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!expanded) {
    return null;
  }

  return (
    <>
      <Box
        cursor="default"
        position="absolute"
        display="flex"
        overflow="hidden" // change to hidden to avoid overflow outside
        style={{
          bottom: 16,
          right: 16,
          width: 350,
          height: 588,
          opacity: selection ? 1 : 0,
          transform: `translateX(${selection ? 0 : 500}px)`,
          transition: selection
            ? `transform var(--easing__enter) var(--duration__125), opacity ease var(--duration__surface)`
            : 'none',
        }}
        boxShadow="Floating"
        backgroundColor="Background Raised"
        borderRadius="Large"
        borderColor="Border Subtle"
        borderWidth="1"
        borderStyle="Solid"
      >
        <Box overflow="auto" style={{flex: 1}}>
          {children}
        </Box>
        {selection !== focus && (
          <IconButton
            tabIndex={selection ? 0 : -1}
            variant="borderless"
            onClick={() => onClose()}
            className={atoms({position: 'absolute'})}
            style={{right: 8, top: 8}}
          >
            <UIIcon src={CloseLine} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

const TeamInfo = ({
  slug,
  onEmployeeClick,
}: {
  slug?: string;
  onEmployeeClick: (ldap: string) => void;
}) => {
  const team = useTeam({slug});

  return (
    <Stack>
      <Stack
        gap="16"
        className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle', padding: '24'})}
      >
        <CircleIcon src={TeamLine} />
        <Box style={{top: 0}} position="sticky" backgroundColor="Background Raised">
          <Title>{team?.name ?? <Skeleton.Text width={160} />}</Title>
        </Box>
        <Text color="subtle" className={atoms({marginY: '8', display: 'block'})}>
          {team?.description ?? <Skeleton.Text width={200} />}
        </Text>
        <Text isBold color="subtle" className={atoms({marginY: '8', display: 'block'})}>
          {team?.total_members ? (
            t('members_count', {count: team?.total_members})
          ) : (
            <Skeleton.Text width={200} />
          )}
        </Text>
        {team?.name && (
          <Split gap="8">
            <Split.Item>
              <IconButton variant="outline">
                <UIIcon src={SlackExternalLogo} />
              </IconButton>
            </Split.Item>
            <Split.Item>
              <IconButton variant="outline">
                <UIIcon src={EmailLine} />
              </IconButton>
            </Split.Item>
          </Split>
        )}
      </Stack>
      <Stack className={atoms({padding: '24'})}>
        {team?.employees.map((member) => (
          <Box
            key={member.ldap}
            onClick={() => onEmployeeClick(member.ldap)}
            cursor="pointer"
            padding="8"
            borderRadius="Medium"
            {...withShade({
              style: {marginLeft: -8, marginRight: -8},
            })}
          >
            <LabelGroup
              withLeftAccessory={<Avatar user={member} />}
              withText={<Text isBold>{member.name}</Text>}
              withSubtext={member.role}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
ChartCard.Team = TeamInfo;

const Employee = ({ldap, onTeamClick}: {ldap?: string; onTeamClick: (slug: string) => void}) => {
  return (
    <>
      <EmployeeInfo ldap={ldap} />
      <EmployeeData ldap={ldap} onTeamClick={onTeamClick} />
    </>
  );
};
ChartCard.Employee = Employee;

const EmployeeInfo = ({ldap}: {ldap?: string}) => {
  const {employee} = useEmployee({ldap});

  return (
    <Stack
      gap="16"
      className={atoms({borderBottom: 'Solid', borderColor: 'Border Subtle', padding: '24'})}
    >
      {!employee ? (
        <Skeleton.Avatar style={{width: 128, height: 128}} />
      ) : (
        <Avatar size="profile" user={employee} />
      )}

      <Box style={{top: 0}} position="sticky" backgroundColor="Background Raised">
        <Title>
          {employee?.name ?? (
            <Skeleton.Box height={28} width={160} className={atoms({marginTop: '8'})} />
          )}
        </Title>
      </Box>
      <Text color="subtle" className={atoms({marginY: '8'})}>
        {employee?.role ?? <Skeleton.Text width={200} />}
        {employee?.level && ` (${employee.level})`}
      </Text>
      {employee?.name && (
        <Split gap="8">
          <Split.Item>
            <IconButton variant="outline">
              <UIIcon src={SlackExternalLogo} />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton variant="outline">
              <UIIcon src={EmailLine} />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton variant="outline">
              <UIIcon src={MobileLine} />
            </IconButton>
          </Split.Item>
        </Split>
      )}
    </Stack>
  );
};

const EmployeeData = ({
  ldap,
  onTeamClick,
}: {
  ldap?: string;
  onTeamClick: (slug: string) => void;
}) => {
  const {data: card} = useQuery({
    queryKey: ['people', 'card', ldap],
    queryFn: () => getService(EmployeeService).getCardApiV1PeopleLdapCardGet(ldap!),
    enabled: !!ldap,
  });

  if (!card) {
    return null;
  }

  return (
    <Stack gap="16" className={atoms({padding: '24'})}>
      {Boolean(card.teams.length) && <Eyebrow>{t('teams')}</Eyebrow>}
      {card.teams.map((team) => (
        <Box
          key={team.slug}
          onClick={() => onTeamClick(team.slug ?? '')}
          cursor="pointer"
          padding="8"
          borderRadius="Medium"
          {...withShade({
            style: {marginLeft: -8, marginRight: -8},
          })}
        >
          <LabelGroup
            withText={<Text isBold>{team.name}</Text>}
            withLeftAccessory={
              <Box
                className={atoms({
                  backgroundColor: 'Opacity Surface',
                  borderRadius: 'Circular',
                  padding: '4',
                })}
                style={{
                  width: 32,
                  height: 32,
                }}
              >
                <UIIcon src={TeamLine} />
              </Box>
            }
            withSubtext={
              <>{team.members_count ? t('members_count', {count: team.members_count}) : null}</>
            }
          />
        </Box>
      ))}
      {Boolean(card.projects.length) && <Eyebrow>{t('projects')}</Eyebrow>}
      {card.projects.map((project) => (
        <Box key={project.id}>
          <LabelGroup
            withText={project.title}
            withLeftAccessory={<UIIcon src={BriefcaseLine} />}
            withSubtext={project.latest_update?.status}
          />
        </Box>
      ))}
    </Stack>
  );
};
