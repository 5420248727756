import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, DeleteLine} from '@dropbox/dig-icons/assets';
import {ProjectLink} from 'client';
import {LinkIcon, linkToIcon} from 'components/shared/LinkIcon';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

import styles from './ProjectLinkEditor.module.css';

const isLinkValid = (url: string, text: string) => {
  if (!url.length && !text.length) {
    return true;
  }
  if (url.length && !text.length) {
    return false;
  }
  if (!/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w.-]*)*(\?[\w=&]*)?$/i.test(url)) {
    return false;
  }
  try {
    new URL(!url.startsWith('http') ? `https://${url}` : url);
    return true;
  } catch {
    return false;
  }
};

export const ProjectLinkEditor = ({
  links = [],
  autoFocus,
  isInvalid,
  updateLinks,
}: {
  autoFocus: boolean;
  links: ProjectLink[];
  isInvalid: boolean;
  updateLinks: (links: ProjectLink[]) => void;
}) => {
  const [hover, setHover] = useState<number | undefined>();

  const addLink = () => updateLinks([...links, {url: '', text: ''} as ProjectLink]);
  const removeLink = (index: number) => updateLinks(links?.filter((_, i) => i !== index) ?? []);

  useEffect(() => {
    if (autoFocus) {
      addLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUrlChange = (url: string, index: number) => {
    const fullUrl =
      url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    const icon = fullUrl && url.length ? linkToIcon(fullUrl) : 'link';

    updateLinks([
      ...links.slice(0, index),
      {...links[index], url, icon},
      ...links.slice(index + 1),
    ]);
  };

  const handleTextChange = (text: string, index: number) =>
    updateLinks([...links.slice(0, index), {...links[index], text}, ...links.slice(index + 1)]);

  return (
    <Stack gap="8">
      {links?.map(({icon, url, text}, index) => (
        <Split
          key={index}
          onMouseEnter={() => setHover(index)}
          onMouseLeave={() => setHover(undefined)}
          className={styles.projectLink}
        >
          <Tooltip title={url.length ? url : t('link_url_tooltip')}>
            <TextInput
              size="large"
              isInvalid={isInvalid && !isLinkValid(url, text)}
              placeholder={t('link_url_placeholder')}
              withLeftAccessory={<LinkIcon icon={icon} />}
              value={url}
              onChange={(e) => handleUrlChange(e.target.value, index)}
              onFocus={(e) => {
                // eslint-disable-next-line no-param-reassign
                e.target.scrollLeft = e.target.scrollWidth;
              }}
            />
          </Tooltip>
          <Box
            marginY="16"
            borderLeft="Solid"
            borderWidth="1"
            borderColor="Border Base"
            style={{height: 24}}
          />
          <TextInput
            size="large"
            placeholder={t('link_text_placeholder')}
            isInvalid={isInvalid && !isLinkValid(url, text)}
            withRightAccessory={
              <IconButton
                style={{visibility: links?.length > 1 && hover === index ? 'visible' : 'hidden'}}
                shape="circular"
                variant="borderless"
                onClick={() => removeLink(index)}
              >
                <UIIcon color="var(--dig-color__border__base)" src={DeleteLine} size="medium" />
              </IconButton>
            }
            value={text}
            onChange={(e) => handleTextChange(e.target.value, index)}
          />
        </Split>
      ))}
      <IconButton onClick={addLink} variant="outline" size="small">
        <UIIcon src={AddLine} className={atoms({color: 'Border Base'})} />
      </IconButton>
    </Stack>
  );
};
