/* eslint-disable no-param-reassign */
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {atoms, Box, ThemeContainer, ThemeProvider, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddLine,
  CloseLine,
  CollectionLine,
  FullscreenLine,
  MinusLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {derivedThemeAtom} from 'atoms/layout';
import cx from 'classnames';
import {EmployeeService, TeamService} from 'client';
import {Title} from 'components/DSYS/Title';
import * as d3 from 'd3';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {getService} from 'utilities';

import styles from './Chart.module.css';
import {ChartCard} from './ChartCard';
import {getData} from './dataUtils';
import {
  adjustDeepestNodes,
  alignPathToRoot,
  centerOnFocusedNode,
  findPathToRoot,
  getUid,
  HierarchyData,
  isEmployee,
  nodeSize,
  padding,
  renderLinks,
  renderStyledNodes,
} from './helpers';
import {useChartExpand, useChartKeyboardShortcuts, useChartResize} from './hooks';

export type OrgChartProps = {
  data?: HierarchyData;
  focus: string;
  toggle: {ldap: string; slug: string};
  setFocus: (focus: string) => void;
  selection?: string;
  setSelection: (selection?: string) => void;
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
  onClick?: (selection: string) => void;
};

const FOCUS_MODE = true;

const maxZoom = 1.4;
const minZoom = 0.7;

export const OrgChart = ({
  data,
  toggle,
  focus,
  setFocus,
  selection,
  expanded,
  setSelection,
  setExpanded,
  onClick,
}: OrgChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<SVGSVGElement, unknown> | null>(null);
  const isLightMode = useAtomValue(derivedThemeAtom) === 'bright';
  const [type, setType] = useState<'employee' | 'team'>();

  const [pageMaxDepth, setPageMaxDepth] = useState<number | undefined>();
  const [children, setChildren] = useState<number | undefined>(undefined);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const dotColor = isLightMode ? '#736c6450' : '#bbb5ae50';

  const {data: peopleTree} = useQuery({
    queryKey: ['people', 'tree', 'all'],
    queryFn: getService(EmployeeService).getTreeAllApiV1PeopleTreeGet,
  });

  const {data: teamTree} = useQuery({
    queryKey: ['teams', 'tree', 'all'],
    queryFn: getService(TeamService).getTreeAllApiV1TeamsTreeGet,
  });

  const hierarchyData = useMemo(() => {
    if (!data) {
      return;
    }

    const root = d3.hierarchy<HierarchyData>(
      getData(expanded, data, focus, type, peopleTree, teamTree, selection)
    );

    let maxDepth = 0;
    // Traverse the tree to find the maximum depth
    root.each((node) => {
      if (node.depth > maxDepth) {
        maxDepth = node.depth;
      }
    });

    setPageMaxDepth(maxDepth);

    const nodes = root.descendants() as d3.HierarchyPointNode<HierarchyData>[];
    const links = root.links() as d3.HierarchyPointLink<HierarchyData>[];

    const pathToRoot = findPathToRoot(nodes, focus);

    return {root, pathToRoot, nodes, links, maxDepth};
  }, [data, expanded, focus, type, peopleTree, teamTree, selection]);

  // useEffect(() => {
  //   if (!data) {
  //     return;
  //   }
  //   isEmployee(data) ? 'employee' : 'team';
  // }, [data]);

  // useEffect(() => {
  //   if (expanded) {
  //     setTimeout(() => {
  //       setSelection(focus);
  //     }, 450);
  //   } else {
  //     setSelection();
  //   }
  // }, [expanded, focus, setSelection]);

  // Function to adjust zoom scale
  const adjustZoom = (zoomFactor: number) => {
    if (!zoomRef.current || !svgRef.current) {
      return;
    }
    const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const point = [svgRef.current.clientWidth / 2, svgRef.current.clientHeight / 2];

    const currentTransform = d3.zoomTransform(svgZoom.node() as SVGSVGElement);
    const newScale = Math.max(minZoom, Math.min(maxZoom, currentTransform.k * zoomFactor));

    // Calculate the new x and y to keep the point centered
    const zoomTransform = d3.zoomIdentity
      .translate(
        point[0] - (point[0] - currentTransform.x) * (newScale / currentTransform.k),
        point[1] - (point[1] - currentTransform.y) * (newScale / currentTransform.k)
      )
      .scale(newScale);

    svgZoom.call(zoomRef.current.transform, zoomTransform);
  };

  const recenterChart = useCallback(
    ({node, resize}: {node?: d3.HierarchyPointNode<HierarchyData>; resize?: boolean}) => {
      if (!zoomRef.current || !svgRef.current || !wrapperRef.current) {
        return;
      }

      if (resize) {
        const rect = wrapperRef.current.getBoundingClientRect();
        svgRef.current.setAttribute('width', rect.width + 'px');
        svgRef.current.setAttribute('height', rect.height + 'px');
      }

      const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const wrapperWidth = wrapperRef.current.clientWidth;
      const wrapperHeight = wrapperRef.current.clientHeight;
      const width = wrapperWidth - padding * 4;
      const height = wrapperHeight - padding * 4;

      const centerNode = node ?? hierarchyData?.nodes?.[0];

      // Center on the selected node or a default position
      centerOnFocusedNode(svgZoom, zoomRef.current, width, height, centerNode, Boolean(expanded));
    },
    [expanded, hierarchyData?.nodes]
  );

  // Reset zoom function
  const resetZoom = useCallback(
    (node?: d3.HierarchyPointNode<HierarchyData>) => {
      if (!zoomRef.current || !svgRef.current || !wrapperRef.current) {
        return;
      }
      const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);

      const currentTransform = d3.zoomIdentity;
      svgZoom.call(zoomRef.current.transform, currentTransform);

      recenterChart({node});
    },
    [recenterChart]
  );

  useChartExpand(wrapperRef, expanded, recenterChart);
  useChartResize(recenterChart);
  useChartKeyboardShortcuts({expanded, resetZoom, adjustZoom});

  useEffect(() => {
    if (!svgRef.current || !hierarchyData) return;

    const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);

    svg.selectAll('*').remove();

    const g = svg.append('g').attr('transform', `translate(${padding * 4}, ${padding * 4})`);

    let currentTransform = d3.zoomIdentity;

    zoomRef.current = d3
      .zoom<SVGSVGElement, unknown>()
      .filter((event) => !['wheel', 'dblclick'].includes(event.type))
      .scaleExtent([minZoom, maxZoom])
      .on('zoom', (event) => {
        currentTransform = event.transform;
        g.attr('transform', currentTransform.toString());
      });

    if (expanded) {
      svg.call(zoomRef.current);

      svg.on('wheel', (event) => {
        event.preventDefault();
        const point = d3.pointer(event, svg.node());

        if (event.ctrlKey || event.metaKey) {
          // Calculate the new zoom scale
          const zoomFactor = event.deltaY > 0 ? 0.96 : 1.04; // Adjusted for less intensity
          const newScale = Math.max(minZoom, Math.min(maxZoom, currentTransform.k * zoomFactor));

          // Compute the new transformation centered around the mouse point
          const zoomTransform = d3.zoomIdentity
            .translate(
              point[0] - (point[0] - currentTransform.x) * (newScale / currentTransform.k),
              point[1] - (point[1] - currentTransform.y) * (newScale / currentTransform.k)
            )
            .scale(newScale);

          // Apply the new zoom transformation
          currentTransform = zoomTransform;
          g.attr('transform', currentTransform.toString());

          // Sync with d3.zoom's internal state
          svg.call(zoomRef.current!.transform, currentTransform);
        } else {
          // Trackpad panning behavior if Ctrl is not pressed
          const deltaX = event.deltaX;
          const deltaY = event.deltaY;
          currentTransform = currentTransform.translate(-deltaX, -deltaY);
          g.attr('transform', currentTransform.toString());

          // Sync with d3.zoom's internal state
          svg.call(zoomRef.current!.transform, currentTransform);
        }
      });
    } else {
      svg.on('wheel', null);
    }

    const {root, pathToRoot, nodes, links, maxDepth} = hierarchyData;

    const treeLayout = d3
      .tree<HierarchyData>()
      .nodeSize([nodeSize(expanded).width + padding, nodeSize(expanded).height + padding])
      .separation(() => 1);

    treeLayout(root);

    const focusedNode = pathToRoot.length > 0 ? pathToRoot[0] : undefined;

    if (focusedNode) {
      if (focusedNode.children?.length) {
        setChildren(
          Math.ceil(focusedNode.children.length / (focusedNode.children.length > 6 ? 3 : 2))
        );
      } else {
        const parentChildren = focusedNode.parent?.children?.length ?? 0;
        const numberOfColumns = parentChildren > 6 ? 3 : 2;

        if (numberOfColumns) {
          treeLayout.nodeSize([
            nodeSize(expanded).width + padding - 40,
            nodeSize(expanded).height + padding,
          ]);
        }

        focusedNode.parent && !isEmployee(focusedNode.parent.data)
          ? setChildren(Math.ceil(parentChildren / numberOfColumns))
          : setChildren(1);
      }
    }

    const selectedNode = selection
      ? nodes.find((node) => getUid(node.data) === selection)
      : undefined;

    if (focusedNode) {
      alignPathToRoot(focusedNode, Boolean(expanded));
      setType(isEmployee(focusedNode.data) ? 'employee' : 'team');
    }

    adjustDeepestNodes(nodes, maxDepth, Boolean(expanded));
    renderLinks(g, links, pathToRoot, maxDepth, Boolean(expanded));
    renderStyledNodes(
      g,
      nodes,
      focusedNode,
      FOCUS_MODE ? focusedNode : selectedNode,
      (e, node) => {
        e.stopPropagation();
        e.preventDefault();
        if (expanded) {
          if (FOCUS_MODE) {
            setFocus(node);
          } else {
            setSelection(node);
          }
        } else {
          onClick?.(node);
          expandedNodes.includes(node)
            ? setExpandedNodes(expandedNodes.filter((n) => n !== node))
            : setExpandedNodes([...expandedNodes, node]);
        }
      },
      Boolean(expanded)
    );

    resetZoom(FOCUS_MODE ? (focusedNode?.parent ?? focusedNode) : selectedNode);
  }, [
    focus,
    expanded,
    type,
    peopleTree,
    onClick,
    resetZoom,
    teamTree,
    expandedNodes,
    setFocus,
    selection,
    setSelection,
    hierarchyData,
  ]);

  useEffect(() => {
    if (!expanded && FOCUS_MODE && data) {
      setFocus(isEmployee(data) ? toggle.ldap : toggle.slug);
    }
  }, [data, expanded, setFocus, toggle.ldap, toggle.slug]);

  return (
    <Box
      ref={wrapperRef}
      borderRadius="Large"
      className={cx(styles.container, {
        [styles.adjustable]: expanded,
      })}
      style={{
        minHeight: `${((pageMaxDepth ?? 0) + (children ?? 0)) * 100}px`,
        backgroundImage:
          // expanded ?
          `radial-gradient(circle, ${dotColor} 1px, var(--dig-color__background__${isLightMode ? 'subtle' : 'raised'}) 1px)`,
        // : `var(--dig-color__background__${isLightMode ? 'subtle' : 'raised'})`,
      }}
    >
      <Box display="flex" alignItems="center" position="absolute" style={{left: 16, top: 16}}>
        <Title
          weightVariant="emphasized"
          size="small"
          withAccessoryStart={<UIIcon src={CollectionLine} />}
        >
          {t('organization')}
        </Title>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        style={{right: 16, top: 16, gap: 8}}
      >
        {expanded && (
          <>
            <Chip
              isSelected={type === 'employee'}
              onClick={() => {
                setType('employee');
                setFocus(toggle.ldap);
                setSelection(toggle.ldap);
              }}
            >
              Reporting line
            </Chip>
            <Chip
              isSelected={type === 'team'}
              onClick={() => {
                setType('team');
                setFocus(toggle.slug);
                setSelection(toggle.slug);
              }}
            >
              Teams
            </Chip>

            <Box>
              <ZoomButton onClick={() => adjustZoom(0.8)} />
              <ZoomButton zoomIn onClick={() => adjustZoom(1.2)} />
            </Box>
          </>
        )}
        <ThemeProvider
          mode={useAtomValue(derivedThemeAtom) === 'bright' && expanded ? 'dark' : 'bright'}
        >
          <ThemeContainer>
            <Box
              as={IconButton}
              variant={expanded ? 'filled' : 'outline'}
              marginLeft="16"
              onClick={() => setExpanded?.(!expanded)}
            >
              <UIIcon
                src={expanded ? CloseLine : FullscreenLine}
                className={atoms({color: expanded ? 'Text Base' : 'Text Subtle'})}
              />
            </Box>
          </ThemeContainer>
        </ThemeProvider>
      </Box>
      <ChartCard
        expanded={Boolean(expanded)}
        selection={FOCUS_MODE ? focus : selection}
        focus={focus}
        onClose={() => {
          setSelection();
        }}
      >
        {data &&
          (type === 'employee' ? (
            <ChartCard.Employee
              ldap={FOCUS_MODE ? focus : selection}
              onTeamClick={(slug) => {
                setSelection(slug);
                setFocus(slug);
                setType('team');
              }}
            />
          ) : (
            <ChartCard.Team
              slug={FOCUS_MODE ? focus : selection}
              onEmployeeClick={(ldap) => {
                setSelection(ldap);
                setFocus(ldap);
                setType('employee');
              }}
            />
          ))}
      </ChartCard>
      <svg ref={svgRef} width="100%" height="100%"></svg>
    </Box>
  );
};

const ZoomButton = ({zoomIn, onClick}: {zoomIn?: boolean; onClick: () => void}) => {
  return (
    <Box
      as="button"
      color="Text Subtle"
      backgroundColor="Background Base"
      borderStyle="Solid"
      borderColor="Border Subtle"
      borderWidth="1"
      cursor="pointer"
      outlineColor="Focus Ring"
      outlineOffset="1"
      onClick={onClick}
      {...withShade({
        direction: 'up',
        style: {
          borderTopLeftRadius: !zoomIn ? 20 : 0,
          borderBottomLeftRadius: !zoomIn ? 20 : 0,
          borderTopRightRadius: !zoomIn ? 0 : 20,
          borderBottomRightRadius: !zoomIn ? 0 : 20,
          borderLeftWidth: !zoomIn ? 1 : 0,
          borderRightWidth: !zoomIn ? 0 : 1,
          paddingLeft: !zoomIn ? 4 : 0,
          paddingRight: !zoomIn ? 0 : 4,
        },
      })}
    >
      <UIIcon
        src={zoomIn ? AddLine : MinusLine}
        size="small"
        className={atoms({margin: '4', marginBottom: '0'})}
      />
    </Box>
  );
};
