import {Table as DIGTable} from '@dropbox/dig-components/dist/table';
import {Box, Split} from '@dropbox/dig-foundations';
import {Title} from 'components/DSYS/Title';
import {Header, TableSort} from 'components/shared/table/Header';
import {LabelGroupCell, LabelGroupSubtleCell} from 'components/shared/table/LabelGroupCell';
import {TableSearch} from 'components/shared/table/TableSearch';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {useState} from 'react';

/*
 *
 *
 *     NOTE
 *
 *     This component is not (yet) a reusable component.
 *
 *
 */

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 470},
    {type: 'status', width: 140},
    {type: 'participation', short: 'par', width: 100},
  ];
};

type DSYSData = {
  id: string;
  name: string;
  status: string;
  responses: number;
  total: number;
};

const DSYSTableRow = ({id, name, status, responses, total}: DSYSData) => {
  return (
    <Box key={id} as={DIGTable.Row} isSelectable>
      <LabelGroupCell text={name} />
      <LabelGroupSubtleCell text={status} />
      <LabelGroupSubtleCell
        text={`${Math.round((responses / (total || 1)) * 100)}%`}
        subText={`${responses} of ${total}`}
      />
    </Box>
  );
};

const sortTable = (sort: TableSort) => (a: DSYSData, b: DSYSData) => {
  const direction = sort.asc ? 1 : -1;

  if (sort.type === 'name') {
    return direction * a.name.localeCompare(b.name);
  }

  if (sort.type === 'status') {
    return direction * a.status.localeCompare(b.status);
  }

  if (sort.type === 'participation') {
    return direction * (a.responses / a.total - b.responses / b.total);
  }

  return 0;
};

const data = [
  {
    id: '1',
    name: 'Youcef',
    status: 'Active',
    responses: 10,
    total: 20,
  },
  {
    id: '2',
    name: 'Kyle',
    status: 'Inactive',
    responses: 15,
    total: 20,
  },
  {
    id: '3',
    name: 'Seth',
    status: 'Active',
    responses: 5,
    total: 20,
  },
  {
    id: '4',
    name: 'Baochen',
    status: 'Active',
    responses: 5,
    total: 20,
  },
  {
    id: '5',
    name: 'Melody',
    status: 'Active',
    responses: 5,
    total: 20,
  },
];

export const Table = ({
  columnConfigs,
  dragging,
  onColumnDrag,
}: {
  columnConfigs: ColumnConfig[];
  dragging?: string;
  onColumnDrag: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const [sort, setSort] = useState({type: 'name', asc: true});
  const [filter, setFilter] = useState({search: ''});
  const tableWidth = 270 + columnConfigs.reduce((acc, {width}) => acc + width, 0);

  const sortedTable = data
    ?.filter(({name}) => name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()))
    .sort(sortTable(sort));

  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      backgroundColor="Background Base"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: tableWidth, overflowX: 'auto'}}
    >
      <Box as={Split} gap="6" alignY="center" marginBottom="4">
        <Split.Item width="fill">
          <Title className="ignore">Table title</Title>
        </Split.Item>
        <Split.Item>
          <Box as="div" paddingY="12" borderLeft="Solid" borderColor="Border Subtle" />
        </Split.Item>
        <Split.Item>
          <TableSearch placeholder="Search..." filter={filter} setFilter={setFilter} />
        </Split.Item>
      </Box>

      <DIGTable hasDividers={false} spacing="small" verticalAlign="center">
        <Header
          columnConfigs={columnConfigs}
          sort={sort}
          setSort={setSort}
          dragging={dragging}
          getMouseDownHandler={onColumnDrag}
        />
        <DIGTable.Body>
          <Box as="div" style={{height: 8}} />
          {sortedTable.map((row) => (
            <DSYSTableRow key={row.id} {...row} />
          ))}
        </DIGTable.Body>
      </DIGTable>
    </Box>
  );
};
