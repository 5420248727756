import {useParams} from 'react-router-dom';
import {ProfileView} from 'views/profile/ProfileView';

const Profile = () => {
  const params = useParams();

  return <ProfileView user={params.userid} />;
};

export {Profile};
