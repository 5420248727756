import {Badge} from '@dropbox/dig-components/badge';
import {Banner} from '@dropbox/dig-components/banner';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {GlobalHeader, WaffleGroup, WaffleItem} from '@dropbox/dig-components/global_header';
import {Menu} from '@dropbox/dig-components/menu';
import {Box, useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {DropboxClientContext} from 'App';
import DropboxOSIcon from 'assets/DropboxOSIcon.svg';
import DropboxOSIconDark from 'assets/DropboxOSIconDark.svg';
import DropboxOSIconDarkMobile from 'assets/DropboxOSIconDarkMobile.svg';
import DropboxOSIconMobile from 'assets/DropboxOSIconMobile.svg';
import {pulseTokenAtom, pulseUserAtom} from 'atoms/auth';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import {filterAtom, showSearchAtom} from 'atoms/search';
import {EmployeeService, NewsService, NotificationService, ProjectService} from 'client';
import {OpenAPI} from 'client/core/OpenAPI';
import {request as __request} from 'client/core/request';
import {ROUTE_PATHS} from 'constant';
import {fetchWaffleData, processWaffleGroupItems, processWaffleMenuItems} from 'helpers/DropboxApi';
import {isDev, isProd} from 'helpers/environment';
import {reportAndLogError} from 'helpers/logging';
import {isOSX} from 'helpers/platform';
import {isContentAdmin, isSuperUser} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getBackendUrl, getLoginService, getLogoutUrl, getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

import {Avatar} from './DSYS/Avatar';
import {Link} from './DSYS/Link';
import {NotificationButton} from './notifications/NotificationButton';
const DROPBOX_PRIVACY_URL = 'https://www.dropbox.com/privacy';
const FEEDBACK_URL = 'https://dropbox.slack.com/archives/companyos-feedback';

const Navbar = () => {
  const {mode} = useTheme();
  const isMobile = useAtomValue(isMobileAtom);
  const navigate = useNavigate();
  const pulseUser = useAtomValue(pulseUserAtom);
  const setPulseToken = useSetAtom(pulseTokenAtom);
  const dropboxClient = useContext(DropboxClientContext);
  const growthbook = useAtomValue(growthbookCacheAtom);
  const setShowSearch = useSetAtom(showSearchAtom);
  const setFilter = useSetAtom(filterAtom);

  const isLoggedIn = !!pulseUser;

  const logoutUrl = getLogoutUrl();

  const [waffleItems, setWaffleItems] = useState<WaffleItem[]>([]);
  const [waffleGroups, setWaffleGroups] = useState<WaffleGroup[]>([]);

  const retrieveWaffleMenuItems = async () => {
    try {
      if (!dropboxClient) {
        return;
      }
      const waffleData = await fetchWaffleData(dropboxClient);
      const menuItems = processWaffleMenuItems(waffleData);
      const groupItems = processWaffleGroupItems(waffleData);

      setWaffleItems(menuItems);
      setWaffleGroups(groupItems);
    } catch {
      setWaffleItems([]);
      setWaffleGroups([]);
    }
  };

  useEffect(() => {
    retrieveWaffleMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const onSelection = useCallback((item: WaffleItem) => {
    const url = item.href;
    window.open(url);
  }, []);

  const handleLogout = () => {
    getLoginService()
      .routeLogoutAndRemoveCookieApiV1AuthLogoutGet()
      .then(() => {})
      .catch((error) => {
        reportAndLogError(error, 'Failed to logout');
      })
      .finally(() => {
        setPulseToken('');
        window.location.href = logoutUrl;
      });
  };

  const handleWorkday = () => {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/people/update',
    });
  };

  const handleTeams = () => {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/teams/update',
    });
  };

  const handleEmployeeCities = () => {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/people/update_employee_cities',
    });
  };

  const handleNearbyCities = () => {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/people/update_nearby_cities',
    });
  };

  const handleSearchClick = () => {
    setFilter('all');
    setShowSearch('nav');
  };

  return (
    <GlobalHeader.Wrapper>
      <GlobalHeader.Waffle items={waffleItems} groups={waffleGroups} onSelection={onSelection} />
      <Link to="/" style={{height: 35}} state={{source: 'navbar'}}>
        <img
          src={
            isMobile
              ? mode === 'dark'
                ? DropboxOSIconDarkMobile
                : DropboxOSIconMobile
              : mode === 'dark'
                ? DropboxOSIconDark
                : DropboxOSIcon
          }
          style={{
            filter: isDev ? 'grayscale(1)' : !isProd ? 'hue-rotate(127deg)' : '',
          }}
        />
      </Link>
      {!isMobile && <Badge>{t('alpha')}</Badge>}

      {!isMobile && (
        <Box display="flex" justifyContent="center" style={{width: '100%'}}>
          <Box
            as={Banner}
            type="attention"
            paddingTop="6"
            style={{height: '35px', minHeight: '35px', width: '700px', maxWidth: '700px'}}
          >
            <Banner.Message>
              The updated reporting lines and teams will be reflected in Workday and CompanyOS on
              11/18.
            </Banner.Message>
          </Box>
        </Box>
      )}

      <Box marginLeft="auto">
        <TextInput
          onClick={handleSearchClick}
          onKeyDown={handleSearchClick}
          tabIndex={-1}
          placeholder={t('search')}
          withLeftAccessory={<UIIcon src={SearchLine} />}
          withRightAccessory={
            <Box color="Border Base">
              {isOSX ? t('search_shortcut_mac') : t('search_shortcut_windows')}
            </Box>
          }
        />
      </Box>
      <Box>
        <GlobalHeader.UtilityNav>
          <GlobalHeader.Help>
            <Menu.Segment>
              <Menu.ActionItem onClick={() => window.open(FEEDBACK_URL, '_blank')}>
                {t('send_feedback')}
              </Menu.ActionItem>
              <Menu.ActionItem onClick={() => window.open(DROPBOX_PRIVACY_URL, '_blank')}>
                {t('privacy_legal')}
              </Menu.ActionItem>
            </Menu.Segment>
            {pulseUser && isContentAdmin(pulseUser.email) && (
              <Menu.Segment withLabel="Content Management">
                <Menu.ActionItem onClick={() => navigate('/manage-badges')}>
                  {t('manage_badges')}
                </Menu.ActionItem>
                <Menu.ActionItem onClick={() => navigate('/manage-cards')}>
                  {t('manage_cards')}
                </Menu.ActionItem>
                <Menu.ActionItem onClick={() => navigate(ROUTE_PATHS.ADMIN_NOTIFICATIONS)}>
                  Manage notifications
                </Menu.ActionItem>
                <Menu.ActionItem onClick={() => navigate(ROUTE_PATHS.ADMIN_PULSE)}>
                  Manage pulse
                </Menu.ActionItem>
                <Menu.ActionItem onClick={() => navigate('/admin/delegates')}>
                  Manage delegation
                </Menu.ActionItem>
              </Menu.Segment>
            )}
            {pulseUser && isSuperUser(pulseUser.email) && (
              <Menu.Segment withLabel="Engineering">
                <Menu.ActionItem onClick={handleWorkday}>Import profiles</Menu.ActionItem>
                <Menu.ActionItem onClick={handleTeams}>Import teams</Menu.ActionItem>
                <Menu.ActionItem
                  onClick={getService(EmployeeService).seedSlackUsersApiV1PeopleSeedSlackGet}
                >
                  Import slack
                </Menu.ActionItem>
                <Menu.ActionItem
                  onClick={() => getService(ProjectService).createProjectsApiV1SeedProjectsGet()}
                >
                  Sync airtable projects
                </Menu.ActionItem>
                <Menu.ActionItem onClick={handleEmployeeCities}>Import cities</Menu.ActionItem>
                <Menu.ActionItem onClick={handleNearbyCities}>Import nearby cities</Menu.ActionItem>
                <Menu.ActionItem onClick={() => navigate('/admin/celery_tasks/')}>
                  Monitor tasks
                </Menu.ActionItem>
              </Menu.Segment>
            )}
            {pulseUser && isSuperUser(pulseUser.email) && !isProd && (
              <Menu.Segment withLabel="Notifications">
                <Menu.ActionItem
                  onClick={() =>
                    getService(NotificationService)
                      .seedApiV1NotificationsSeedGet()
                      .then(() => queryClient.invalidateQueries({queryKey: ['notifications']}))
                  }
                >
                  Seed notifications
                </Menu.ActionItem>
                <Menu.ActionItem
                  onClick={() =>
                    getService(NotificationService)
                      .wipeApiV1NotificationsWipeGet()
                      .then(() => queryClient.invalidateQueries({queryKey: ['notifications']}))
                  }
                >
                  Delete yours
                </Menu.ActionItem>

                {/* createProjectsApiV1SeedProjectsGet */}
                <Menu.ActionItem
                  onClick={() =>
                    getService(NewsService)
                      .seedApiV1NewsSeedGet()
                      .then(() => queryClient.invalidateQueries({queryKey: ['news']}))
                  }
                >
                  Seed news
                </Menu.ActionItem>
              </Menu.Segment>
            )}
          </GlobalHeader.Help>
          <NotificationButton />

          {!pulseUser ? (
            <Box as={Skeleton.Avatar} size="small" marginTop="4" marginLeft="8" marginRight="4" />
          ) : (
            <GlobalHeader.AccountMenu
              src={getBackendUrl() + pulseUser?.profile_photo_url}
              initials={pulseUser?.abbreviated_name ?? ''}
            >
              <Menu.Segment>
                <Link to={'/people/' + pulseUser?.ldap} hasNoUnderline>
                  <Menu.ActionItem
                    withLeftAccessory={pulseUser && <Avatar user={pulseUser} size="large" />}
                    withSubtitle={pulseUser?.email}
                    withTitle={pulseUser?.display_name}
                  ></Menu.ActionItem>
                </Link>
              </Menu.Segment>
              <Menu.Segment>
                {growthbook.isSpritesAiEnabled && (
                  <Menu.ActionItem onClick={() => navigate(ROUTE_PATHS.REVIEW)}>
                    Generate my review <Badge>Alpha</Badge>
                  </Menu.ActionItem>
                )}
                <Menu.ActionItem onClick={() => navigate(ROUTE_PATHS.SETTINGS)}>
                  {t('settings')}
                </Menu.ActionItem>
                <Menu.ActionItem onClick={handleLogout}>{t('sign_out')}</Menu.ActionItem>
              </Menu.Segment>
            </GlobalHeader.AccountMenu>
          )}
        </GlobalHeader.UtilityNav>
      </Box>
    </GlobalHeader.Wrapper>
  );
};

export {Navbar};
