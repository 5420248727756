import {Button} from '@dropbox/dig-components/dist/buttons';
import {useQuery} from '@tanstack/react-query';
import {Title} from 'components/DSYS/Title';
import {t} from 'i18next';
import {FunctionComponent, useMemo, useState} from 'react';
import {getEmployeeService} from 'utilities';

import {BadgeItem} from './BadgeItem';
import {BadgeModal} from './BadgeModal';
import styles from './index.module.css';

interface Props {
  ldap: string;
}

export const ProfileBadges: FunctionComponent<Props> = ({ldap}) => {
  const {data: badges} = useQuery({
    queryKey: ['badges', ldap],
    queryFn: () => getEmployeeService().getBadgesApiV1PeopleUserIdReceivedGet(ldap),
  });

  const [allBadgesOpen, setAllBadgesOpen] = useState(false);
  const collected = useMemo(() => {
    if (!badges) return [];
    return badges.map((badge) => badge.id);
  }, [badges]);

  if (!badges || badges.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.profiles} id="badges">
      <Title>{t('badges')}</Title>
      <ul>
        {badges.map((badge) => (
          <BadgeItem badge={badge} key={badge.id} collected={collected} />
        ))}
      </ul>
      <Button size="small" variant="transparent" onClick={() => setAllBadgesOpen(true)}>
        {t('view_all')}
      </Button>
      {allBadgesOpen && (
        <BadgeModal
          collected={collected}
          badge={null}
          onRequestClose={() => setAllBadgesOpen(false)}
          closeModal={() => setAllBadgesOpen(false)}
        />
      )}
    </div>
  );
};
