import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {FolderMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {Employee, EmployeeService} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Link} from 'components/DSYS/Link';
import {DEFAULT_TIMEFRAME} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getService} from 'utilities';
import {TimeframeSelector} from 'views/goals_v2/TimeframeSelector';
import {ProjectCard} from 'views/projects/ProjectCard';

export const ProfileProjects = ({employee}: {employee: Employee}) => {
  const location = useLocation();
  const [projectTimeframe, setProjectTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );

  const {isProjectsEnabled} = useAtomValue(growthbookCacheAtom);
  const {data} = useQuery({
    enabled: isProjectsEnabled,
    queryKey: ['employee', 'projects', employee.user_id, projectTimeframe],
    queryFn: () =>
      getService(EmployeeService).getProjectsApiV1PeopleEmployeeIdProjectsGet(
        employee.user_id,
        projectTimeframe
      ),
  });
  const isOldTimeframe = projectTimeframe !== DEFAULT_TIMEFRAME;

  const {data: hasProjects} = useQuery({
    enabled: isProjectsEnabled,
    queryKey: ['employee', 'projects', 'has', employee.user_id],
    queryFn: () =>
      getService(EmployeeService).hasProjectsApiV1PeopleEmployeeIdProjectsHasGet(employee.user_id),
  });

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  if (!hasProjects || !data) {
    return null;
  }

  return (
    <Box as={Stack} width="100%">
      <Split paddingBottom="8">
        <Split.Item width="fill">
          <Text isBold size="xlarge">
            {t('projects')}
          </Text>
        </Split.Item>
        <Split.Item>
          <TimeframeSelector
            selectedTimeframe={projectTimeframe}
            setSelectedTimeframe={(next) => {
              analyticsLogger().logEvent('PROFILE_PROJECT_TIMEFRAME', {timeframe: next});
              setProjectTimeframe(next);
            }}
          />
        </Split.Item>
      </Split>
      {data.projects.length ? (
        data.projects.map((project) => (
          <Box
            key={project.id}
            padding="8"
            {...withShade({duration: 'None'})}
            borderRadius="Medium"
          >
            <Link to={`/projects/${project.id}`} hasNoUnderline>
              <ProjectCard
                isProfile
                title={project.title}
                subtitle={project.subtitle}
                projectId={project.id}
                onStatusClick={() => profileActionLog('project')}
              />
            </Link>
          </Box>
        ))
      ) : (
        <EmptyState
          title={isOldTimeframe ? 'No projects for this quarter' : 'No projects just yet'}
          body={
            isOldTimeframe
              ? 'Try switching the quarter to another one.'
              : 'Projects will show up here once added, so check again later!'
          }
          image={<FolderMini width={64} altText={''} />}
        />
      )}
    </Box>
  );
};
