/* eslint-disable no-param-reassign */
import {EmployeeWithHierarchy, TeamWithHierarchy} from 'client';
import * as d3 from 'd3';

import {getUid, HierarchyData} from './helpers';

export const getData = (
  expanded: boolean,
  data: HierarchyData,
  focus: string,
  type: 'employee' | 'team' | undefined,
  peopleTree: EmployeeWithHierarchy | undefined,
  teamTree: TeamWithHierarchy | undefined,
  selection?: string
) =>
  !expanded
    ? removePeers(data, focus)
    : getPeersAndChildren((type === 'employee' ? peopleTree : teamTree) ?? data, focus, selection);

const removePeers = (data: HierarchyData, focus: string): HierarchyData => {
  const clonedData = structuredClone(data); // Deep copy of data

  console.log('removePeers', clonedData);

  const processData = (node: HierarchyData) => {
    if (node.children) {
      const isManager = node.children.some((child) => getUid(child) === focus);

      node.children = (node.children as HierarchyData[] | undefined)?.filter((child) => {
        if (child.children) {
          processData(child);
        }

        const isFocusedChild = getUid(child) === focus;

        if (isManager) {
          return isFocusedChild || child.children?.some((c) => getUid(c) === focus);
        }

        return true;
      }) as EmployeeWithHierarchy[] | TeamWithHierarchy[] | undefined;
    }
  };

  processData(clonedData);
  return clonedData;
};

const getPeersAndChildren = (
  data: HierarchyData,
  focus: string,
  selection?: string
): HierarchyData => {
  const root = d3.hierarchy(data);

  // Locate the focus node and, optionally, the selection node
  const focusNode = root.find((node) => getUid(node.data) === focus);
  const selectedNode = root.find((node) => selection === getUid(node.data));

  let processData: HierarchyData | undefined = undefined;

  const ancestors = focusNode?.ancestors();

  if (ancestors?.length === 1) {
    return {
      ...ancestors[0].data,
      children: ancestors[0].children?.map((child) => ({
        ...child.data,
        children:
          selectedNode === child
            ? child.children?.map((grandChild) => ({
                ...grandChild.data,
                children: [],
              }))
            : [],
      })) as any,
    };
  }

  // Traverse ancestors from the focus node up to the root
  focusNode?.ancestors().forEach((node) => {
    const isParentOfFocus = node === focusNode.parent;
    // const isGrandparentOfFocus = node === focusNode.parent?.parent;

    if (isParentOfFocus) {
      // For the immediate parent of the focus, include all its children (peers of focus)
      processData = {
        ...node.data,
        children: node.children?.map((child) => ({
          ...child.data,
          children:
            child === focusNode || selectedNode === child
              ? child.children?.map((grandChild) => ({
                  ...grandChild.data,
                  children:
                    selectedNode === grandChild
                      ? grandChild.children?.map((greatGrandChild) => ({
                          ...greatGrandChild.data,
                          children: [],
                        }))
                      : [],
                }))
              : [],
        })) as any,
      };
    } else {
      // For other nodes along the path (including the root), keep only the single path down to the focus node
      processData = {
        ...node.data,
        children: processData ? [processData] : [],
      } as HierarchyData;
    }
  });

  return processData!;
};
